import { Box, Slider, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Colors } from '../../../assets/mui-styles/Colors';

export const CustomInputWithSlider = ({
  error,
  label,
  value = 0,
  helperText,
  onChange,
  inputComponent,
  endAdornment,
  min = 0,
  max = 1,
  minValueText,
  maxValueText,
  inputType,
  onChangeCommitted = _ => {},
  valueSlider = value,
  valueInput = value,
  onChangeSlider = onChange,
  onChangeInput = onChange,
  fetchStatus = undefined,
}) => {
  const marks = useMemo(() => {
    const step = (max - min) / 6;
    return [
      {
        value: min,
      },
      {
        value: min + step,
      },
      {
        value: min + step * 2,
      },
      {
        value: min + step * 3,
      },
      {
        value: min + step * 4,
      },
      {
        value: min + step * 5,
      },
      {
        value: max,
      },
    ];
  }, [min, max]);

  return (
    <Box sx={{ width: '100%' }}>
      <TextField
        error={error}
        label={label}
        color='secondary'
        sx={{ backgroundColor: Colors.primary, width: '100%' }}
        value={valueInput}
        type={inputType}
        helperText={helperText}
        variant='outlined'
        name='numberformat'
        id='formatted-numberformat-input'
        InputProps={{
          inputComponent,
          endAdornment,
        }}
        onChange={e => {
          if (fetchStatus === 'pending') return;
          e.target.value = e.target.value.replace(/^0/, '');
          const value = Number(e.target.value.replaceAll(' ', '').replaceAll('%', '').replaceAll('₽', ''));
          onChangeInput(value);
        }}
      />

      <Box sx={{ height: '60px', padding: '0 12px', display: 'flex', alignItems: 'center', position: 'relative' }}>
        <Slider
          color='secondary'
          min={min}
          max={max}
          value={valueSlider}
          onChange={(_, value) => onChangeSlider(value)}
          onChangeCommitted={(_, value) => onChangeCommitted(value)}
          marks={marks}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            width: 'calc(100% - 24px)',
            bottom: '0',
          }}
        >
          <Typography sx={{ fontSize: '12px', lineHeight: '150%' }}>{minValueText}</Typography>
          <Typography sx={{ fontSize: '12px', lineHeight: '150%' }}>{maxValueText}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
