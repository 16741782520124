import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { apiRoutes } from './api/api';

export const presentationApi = createApi({
  reducerPath: 'presentationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getPresentation: builder.query({
      query: ({ blockId, meetingId }) => `${apiRoutes.presentationApi}?blockId=${blockId}&meetingId=${meetingId}`,
    }),
    getPresentationById: builder.query({
      query: ({ blockId, bulkId, meetingId, name }) => {
        const queryParams = [];
        if (blockId) {
          queryParams.push(`blockId=${blockId}`);
        }
        if (bulkId) {
          queryParams.push(`bulkId=${bulkId}`);
        }
        if (name) {
          queryParams.push(`name=${name}`);
        }
        if (meetingId) {
          queryParams.push(`meetingId=${meetingId}`);
        }
        return `${apiRoutes.presentationApi}?${queryParams.join('&')}`;
      },
    }),
  }),
});

export const { useGetPresentationQuery, useLazyGetPresentationByIdQuery } = presentationApi;
