import { Box, Card, InputAdornment, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Colors } from '../../assets/mui-styles/Colors';
import { toFormat } from '../../utils/utils';
import style from './Calculator.module.scss';
import { initialProjects } from './calculator-logic/initialProjects';
import { CustomInput } from './ui/CustomInput';
import { CustomInputWithSlider } from './ui/CustomInputWithSlider';
import { CustomSelect } from './ui/CustomSelect';
import { CustomSwitch } from './ui/CustomSwitch';
import { NumberSumFormatCustom, PercentFormatCustom } from './ui/InputComponentsForInputWithSlider';

export const CalculatorForPrice = ({
  activeProject,
  handleChangeActiveProjectId,
  benefit,
  benefits,
  handleChangeBenefit,
  handleChangeFirstPaymentCommitted,
  handleChangeFirstPayment,
  firstPayment,
  term,
  handleChangeTerm,
  handleChangeTermCommitted,
  isSetMinFirstPaymentChecked,
  handleSetMinFirstPayment,
  percentFirstPayment,
  isPercent,
  handleChangePercentValue,
  handleSwitchValue,
  maxFirstPayment,
  minFirstPayment,
  priceWithMargin,
  benefitGroup,
  groups,
  handleChangeBenefitGroup,
  flatPrice,
  handleChangeFlatPrice,
  benefitGroupHasChanged,
  projectHasChanged,
  minFlatPrice,
  maxFlatPrice,
  maxTerm,
  minTerm,
  fetchStatus,
}) => {
  const [errorFirstPayment, setErrorFirstPayment] = useState('');
  const [errorTerm, setErrorTerm] = useState('');
  const [errorFlatPrice, setErrorFlatPrice] = useState('');
  useEffect(() => {
    if (firstPayment < minFirstPayment) {
      setErrorFirstPayment('Увеличьте сумму первого взноса');
    } else if (firstPayment > maxFirstPayment) {
      setErrorFirstPayment('Уменьшите сумму первого взноса');
    } else {
      setErrorFirstPayment('');
    }
  }, [firstPayment, maxFirstPayment, minFirstPayment]);

  useEffect(() => {
    if (maxFlatPrice < minFlatPrice || Number(flatPrice) === 0) {
      setErrorFlatPrice('Увеличьте стоимость квартиры');
    } else {
      setErrorFlatPrice('');
    }
  }, [maxFlatPrice, minFlatPrice, flatPrice]);

  useEffect(() => {
    if (term < minTerm) {
      setErrorTerm('Увеличьте срок кредита');
    } else if (term > maxTerm) {
      setErrorTerm('Уменьшите срок кредита');
    } else {
      setErrorTerm('');
    }
  }, [maxTerm, minTerm, term]);
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        background: Colors.additional,
        border: '1px solid',
        borderColor: Colors.superLightGrey,
        borderTopColor: 'transparent',
        p: '24px',
      }}
    >
      <Typography variant='h3' className={style.title}>
        Рассчитать платеж
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: '24px',
          gridTemplateColumns: 'minmax(0, 1fr) 13vw',
        }}
      >
        <CustomSelect
          label='Выберите проект'
          items={initialProjects}
          currentItem={activeProject}
          handleChangeCurrentItem={value => {
            projectHasChanged();
            handleChangeActiveProjectId(value);
          }}
        />
        <CustomSelect
          label='Выберите группировку'
          items={groups}
          currentItem={benefitGroup}
          handleChangeCurrentItem={value => {
            benefitGroupHasChanged();
            handleChangeBenefitGroup(value);
          }}
        />

        {benefits.length > 0 && benefitGroup?.id !== '' && (
          <CustomSelect
            label='Выберите программу'
            handleChangeCurrentItem={handleChangeBenefit}
            currentItem={benefit}
            items={benefits}
          />
        )}
        {benefits.length > 0 && benefitGroup?.id !== '' && (
          <CustomInput
            helperText={errorFlatPrice}
            error={maxFlatPrice < minFlatPrice || Number(flatPrice) === 0}
            valueInput={flatPrice}
            endAdornment={<InputAdornment position='end'>₽</InputAdornment>}
            label='Стоимость квартиры'
            inputComponent={NumberSumFormatCustom}
            onChangeInput={handleChangeFlatPrice}
            fetchStatus={fetchStatus}
          />
        )}

        {benefits.length > 0 &&
          benefitGroup?.id !== '' &&
          !errorFlatPrice &&
          flatPrice !== 'undefined' &&
          Number(flatPrice) !== 0 &&
          benefit && (
            <>
              <CustomInputWithSlider
                helperText={errorFirstPayment}
                error={firstPayment < minFirstPayment || firstPayment > maxFirstPayment}
                onChangeInput={isPercent ? handleChangePercentValue : handleChangeFirstPaymentCommitted}
                onChangeSlider={handleChangeFirstPayment}
                valueSlider={firstPayment}
                valueInput={isPercent ? percentFirstPayment : firstPayment}
                label='Сумма первоначального взноса'
                min={minFirstPayment}
                max={maxFirstPayment}
                minValueText={
                  isPercent
                    ? `${Math.round(((minFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                    : toFormat(minFirstPayment)
                }
                maxValueText={
                  isPercent
                    ? `${Math.round(((maxFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                    : toFormat(maxFirstPayment)
                }
                inputComponent={isPercent ? PercentFormatCustom : NumberSumFormatCustom}
                endAdornment={
                  <InputAdornment position='end'>
                    {isPercent ? toFormat(firstPayment) : `${percentFirstPayment} %`}
                  </InputAdornment>
                }
                onChangeCommitted={handleChangeFirstPaymentCommitted}
              />
              <CustomInputWithSlider
                helperText={errorTerm}
                error={term < minTerm || term > maxTerm}
                onChange={handleChangeTerm}
                onChangeCommitted={handleChangeTermCommitted}
                onChangeInput={handleChangeTermCommitted}
                value={term}
                label='Срок кредита'
                min={minTerm}
                max={maxTerm}
                minValueText={minTerm}
                maxValueText={maxTerm}
                inputType='number'
                fetchStatus={fetchStatus}
              />
            </>
          )}
      </Box>

      {benefits.length > 0 &&
        benefitGroup?.id !== '' &&
        !errorFlatPrice &&
        flatPrice !== 'undefined' &&
        Number(flatPrice) !== 0 &&
        benefit && (
          <Stack sx={{ alignItems: 'flex-start' }}>
            <CustomSwitch onClick={handleSwitchValue} checked={isPercent} label='Сумма первого взноса, %' />
            <CustomSwitch
              onClick={handleSetMinFirstPayment}
              checked={isSetMinFirstPaymentChecked}
              label='Установить минимальный первый взнос'
            />
          </Stack>
        )}
    </Card>
  );
};
