import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setInternetConnection } from '../entities/connectionSlice';

const useOnlineStatus = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateConnectionStatus = () => {
      const isConnected = navigator.onLine;
      dispatch(setInternetConnection(isConnected));
      sessionStorage.setItem('isInternetConnected', isConnected ? 'true' : 'false');
    };

    updateConnectionStatus();

    window.addEventListener('online', updateConnectionStatus);
    window.addEventListener('offline', updateConnectionStatus);

    return () => {
      window.removeEventListener('online', updateConnectionStatus);
      window.removeEventListener('offline', updateConnectionStatus);
    };
  }, [dispatch]);
};

export default useOnlineStatus;
