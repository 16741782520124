import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { userRolesSelector } from '../../entities/AuthSlice';
import ROUTES from '../../entities/api/routes';
import { clearUserData } from '../../utils/utils';

const GuardRoute = ({ Component, ...rest }) => {
  const [hasAccess, setHasAccess] = useState(true);

  const user = JSON.parse(localStorage.getItem('user'));
  const isUser = Boolean(user && user.id && user.firstName && user.email);

  const { allowedRoles } = rest;
  const userRoles = useSelector(userRolesSelector);
  const location = useLocation();

  useEffect(() => {
    if (allowedRoles) {
      const checkSuccess = userRoles.some(role => allowedRoles.includes(role));
      if (import.meta.env.VITE_BLOCKED_AGENCY_DISPLAY_19451 === 'true') {
        const checkStatus = user?.agencyStatus !== 'blocked';
        setHasAccess(checkSuccess && checkStatus);
      } else {
        setHasAccess(checkSuccess);
      }
    }
  }, [userRoles, allowedRoles]);

  useLayoutEffect(() => {
    if (!isUser) {
      clearUserData();
    }
  }, [isUser]);

  if (!hasAccess) {
    return <Navigate to={ROUTES.forbidden} state={{ from: location }} replace />;
  }
  return Component;
};

export default GuardRoute;
