import { Button } from '@mui/material';
import { useEffect } from 'react';
import style from './ButtonFull.module.scss';

const ButtonFull = ({ handleFunction, text, color }) => {
  const termStorage = sessionStorage.getItem('term');
  const activeProject = sessionStorage.getItem('activeProject');
  const currFlatStorage = sessionStorage.getItem('currFlat');
  const currBenefitStorage = sessionStorage.getItem('currBenefit');
  const currBenefitLotStorage = sessionStorage.getItem('currBenefitLot');
  const firstPaymentStorage = sessionStorage.getItem('firstPayment');

  useEffect(() => {
    if (
      activeProject &&
      currFlatStorage &&
      (currBenefitStorage || currBenefitLotStorage) &&
      firstPaymentStorage &&
      termStorage
    ) {
      setTimeout(() => {
        handleFunction();
      }, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button className={style.ButtonFullBtn} onClick={handleFunction} variant='contained' color={color}>
      {text}
    </Button>
  );
};

export default ButtonFull;
