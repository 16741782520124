import { useEffect, useState } from 'react';

export const useSelectFlatPrice = uniqueSuffix => {
  const flatPriceName = `currFlatPrice${uniqueSuffix}`;
  const [flatPrice, setFlatPrice] = useState(sessionStorage.getItem(flatPriceName));

  const handleChangeFlatPrice = value => {
    setFlatPrice(value);
    sessionStorage.setItem(flatPriceName, value);
  };

  useEffect(() => {
    const flatPriceFromSS = sessionStorage.getItem(flatPriceName);
    if (flatPriceFromSS && flatPriceFromSS !== 'undefined') {
      setFlatPrice(flatPriceFromSS);
    }
  }, [flatPriceName]);

  return { flatPrice, handleChangeFlatPrice };
};
