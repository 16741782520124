import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';

const initialState = {
  display: sessionStorage.getItem('accredDisplay') || 'items',
  correctionItems: JSON.parse(sessionStorage.getItem('accredCorrectionItems')) || { documents: [], users: [] },
  activRevision: JSON.parse(sessionStorage.getItem('activRevision')) || false,
};

export const AccreditationSlice = createSlice({
  name: 'accreditationSlice',
  initialState,
  reducers: {
    //изменение страницы
    changeDisplayTab: (state, action) => {
      sessionStorage.setItem('accredDisplay', action.payload);
      state.display = action.payload;
    },
    //Блоки для корректировки
    changeCorrectionItems: (state, action) => {
      const correctionItemsString = JSON.stringify(action.payload);
      sessionStorage.setItem('accredCorrectionItems', correctionItemsString);
      state.correctionItems = action.payload;
    },
    //Блок кнопок активных
    changeActivRevision: (state, action) => {
      sessionStorage.setItem('activRevision', action.payload);
      state.activRevision = action.payload;
    },
  },
});

export const accreditationApi = createApi({
  reducerPath: 'accreditationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getAcrreditationFull: builder.query({
      query: ({ status }) =>
        `/api/accreditation?pagination={"page":1,"perPage":10000}&sort={"field":"id","order":"ASC"}&filter={"status":${status}}`,
      invalidatesTags: [{ type: 'AccreditationApi', id: 'USER' }],
    }),
    getAcrreditationUser: builder.query({
      query: userId => `/api/accreditation/${userId}`,
      invalidatesTags: [{ type: 'AccreditationApi', id: 'USER' }],
    }),
    updateAcrreditationUser: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/api/accreditation/${userId}/status`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'AccreditationApi', id: 'USER' }],
    }),
  }),
});

export const { changeDisplayTab, changeCorrectionItems, changeActivRevision } = AccreditationSlice.actions;
export default AccreditationSlice.reducer;

export const { useGetAcrreditationFullQuery, useGetAcrreditationUserQuery, useUpdateAcrreditationUserMutation } =
  accreditationApi;
