import { FormControlLabel, Switch } from '@mui/material';

export const CustomSwitch = ({ checked, onClick, label }) => {
  return (
    <FormControlLabel
      sx={{ margin: '6px 0' }}
      control={<Switch color='secondary' />}
      onChange={onClick}
      checked={checked}
      label={label}
    />
  );
};
