import { PrimaryColors } from './Colors';

export const typographySettings = {
  fontFamily: ['"Unica77LLCyr-Regular"', 'Unica77LLCyr-Medium', 'sans-serif'].join(','),
  body1: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(14px, 14px + (18 - 14) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '20px',
    letterSpacing: '-0.005em',
  },
  body1M: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(20px, 20px + (24 - 20) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '27px',
    letterSpacing: '-0.005em',
  },
  body1R: {
    fontFamily: 'Unica77LLCyr-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(max(18px, 18px + (22 - 18) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '27px',
    letterSpacing: '-0.005em',
  },
  body2: {
    fontFamily: 'Unica77LLCyr-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(max(14px, 14px + (18 - 14) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '20px',
    letterSpacing: '-0.005em',
  },
  body2R: {
    fontFamily: 'Unica77LLCyr-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(max(14px, 14px + (18 - 14) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '20px',
    letterSpacing: '-0.005em',
  },
  h2: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(37px, 37px + (41 - 37) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '44px',
    letterSpacing: '-0.5px',
  },
  h3: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(28px, 28px + (32 - 28) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '33px',
    letterSpacing: '-0.03em',
  },
  h4: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(24px, 24px + (28 - 24) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '27px',
    letterSpacing: '-0.005em',
  },
  h5: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(20px, 20px + (24 - 20) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '27px',
    letterSpacing: '-0.5px',
  },
  h6: {
    fontFamily: 'Unica77LLCyr-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(max(18px, 18px + (22 - 18) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '27px',
    letterSpacing: '-0.005em',
  },
  buttonSmall: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(14px, 14px + (18 - 14) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '22px',
    letterSpacing: '-0.005em',
  },
  buttonMedium: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(14px, 14px + (18 - 14) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '24px',
    letterSpacing: '-0.005em',
    textTransform: 'none',
  },
  buttonLarge: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(15px, 15px + (19 - 15) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '26px',
    letterSpacing: '0.46px',
  },
  inputLabel: {
    fontFamily: 'Unica77LLCyr-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(max(16px, 16px + (20 - 16) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '12px',
    letterSpacing: '-0.5%',
    textTransform: 'none',
  },
  inputText: {
    fontFamily: 'Unica77LLCyr-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(max(16px, 16px + (20 - 16) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontFamily: 'Unica77LLCyr-Regular',
    fontSize: 'calc(max(12px, 12px + (16 - 12) * ((100vw - 1600px) / (2560 - 1600))))',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.06px',
  },
  subtitle2: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(12px, 12px + (16 - 12) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '20px',
    letterSpacing: '-0.005em',
  },
  chip: {
    fontFamily: 'Unica77LLCyr-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(max(13px, 13px + (17 - 13) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '18px',
    letterSpacing: '0.16px',
  },
  tooltip: {
    fontFamily: 'Unica77LLCyr-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'calc(max(10px, 10px + (14 - 10) * ((100vw - 1600px) / (2560 - 1600))))',
    lineHeight: '14px',
    letterSpacing: 0,
    color: PrimaryColors.white,
  },
  ellipsisOverflow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};
