import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EqualizerFlatCardNew } from '../equalizer-flat-card/EqualizerFlatCard';

export const EqualizerFlatsSliderNew = ({ favoriteFlats, propsForFlatCards }) => {
  return (
    <Swiper
      style={{ height: 'auto', width: '100%' }}
      resistance={true}
      resistanceRatio={0}
      preventClicks={true}
      preventInteractionOnTransition={true}
      slidesPerView='auto'
      spaceBetween={20}
    >
      {favoriteFlats.map((flat, i) => {
        return (
          <SwiperSlide key={i} style={{ width: 'fit-content' }}>
            <EqualizerFlatCardNew {...propsForFlatCards} flat={flat} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
