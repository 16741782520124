import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFirstPageFull: false,
};

export const formStageSlice = createSlice({
  name: 'formStage',
  initialState,
  reducers: {
    setIsFirstPageFull(state, action) {
      state.isFirstPageFull = action.payload;
    },
  },
});

export const { setIsFirstPageFull } = formStageSlice.actions;

export default formStageSlice.reducer;
