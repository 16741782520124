import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Card, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../assets/mui-styles/Colors';
import { handleOpenModelWarning } from '../../entities/ComponentsSlice';
import { changeWarningBenefit, warningBenefitSelector } from '../../entities/ProjectSlice';
import { handleBodyLock, handleBodyUnlock } from '../../utils/utils';
import style from './ModalWarning.module.scss';

function ModalWarning() {
  handleBodyLock();
  const textModelWarning = useSelector(state => state.componentsState.textModelWarning);
  const warningBenefit = useSelector(warningBenefitSelector());
  const dispatch = useDispatch();
  const handleModelClose = () => {
    dispatch(handleOpenModelWarning({ open: false, text: '' }));
    if (warningBenefit) {
      dispatch(changeWarningBenefit(false));
    }
    handleBodyUnlock();
  };
  return (
    <Box className={style.modalWarningContainer}>
      <Card className={style.modalWarningCard}>
        <IconButton
          onClick={handleModelClose}
          className={style.modalWarningBtnClose}
          sx={{
            backgroundColor: Colors.backGrey,
            '&:hover': {
              backgroundColor: Colors.hoverGrey,
            },
          }}
        >
          <CloseIcon color='neutral' />
        </IconButton>
        <WarningAmberIcon color='warning' sx={{ fontSize: '80px', mb: 2 }} />
        <Typography component='div' variant='h4' color='warning' sx={{ color: Colors.warning }}>
          Предупреждение:
        </Typography>
        <Typography component='div' variant='h6' sx={{ mt: 3, width: '70%', textAlign: 'center' }}>
          {textModelWarning}
        </Typography>
      </Card>
    </Box>
  );
}

export default ModalWarning;
