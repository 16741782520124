import {
  addNewTermPrice,
  changeCurrBenefit,
  changeCurrBenefitGroup,
  changeCurrBenefitLot,
  changeCurrBenefitPrice,
  chooseCurrBulk,
  chooseCurrFlat,
  chooseCurrHouse,
  chooseCurrSection,
} from '../entities/ProjectSlice';

export const defaultCalculatorState = dispatch => {
  dispatch(changeCurrBenefitPrice(null));
  dispatch(changeCurrBenefitLot(null));
  dispatch(changeCurrBenefitGroup(''));
  dispatch(chooseCurrSection(null));
  dispatch(chooseCurrHouse(null));
  dispatch(chooseCurrBulk(null));
  dispatch(chooseCurrFlat(null));
  dispatch(changeCurrBenefit({}));
  dispatch(addNewTermPrice(null));
};
