import { useNavigate } from 'react-router-dom';
import ROUTES from '../../entities/api/routes';
import styles from './ContactCard.module.scss';
import DOMPurify from 'dompurify';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const formatPhones = arrPhones => {
  return arrPhones.reduce((acc, phone) => `${acc}<span>${phone[0] === '7' ? `+${phone}` : phone}</span>`, '');
};

const ContactCard = ({ info, func }) => {
  const navigate = useNavigate();

  const onClickCard = id => {
    navigate(`${ROUTES.waba.leads}/${id}`);
    func?.();
  };

  return (
    <div className={styles.card} onClick={() => onClickCard(info?.id)}>
      <div className={styles.info}>
        <h6 className={styles.info_title}>{info?.title || ''}</h6>
        <div className={styles.info_blockTop}>
          <p className={styles.info_name}>
            {info?.name || ''} {info?.lastName || ''}
          </p>
          <p className={styles.info_id}>#{info?.id || ''}</p>
        </div>
        <div className={styles.info_blockBottom}>
          <div
            className={styles.info_number}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatPhones(info?.phone || [])) }}
          />
          <p className={styles.info_status}>Статус: {info?.status || ''}</p>
        </div>
      </div>

      <KeyboardArrowRightIcon className={styles.icon} />
    </div>
  );
};

export default ContactCard;
