import { createSlice } from '@reduxjs/toolkit';
import { authApi } from './AuthSlice';
import { benefitsDataApi } from './BenefitsPageSlice.js';
import { meetingsApi } from './MeetingsSlice';
import { presentationApi } from './PresentationsSlice.js';
import { contactsApi } from './WabaSlice.js';
import { bookingApi } from './BookingSlice.js';
import { formApi } from './formSlice.js';

const initialState = {
  activeData: true,
  activeSwitch: sessionStorage.getItem('activeSwitch') || 'lot',
  isLoading: false,
  error: {
    code: '',
    message: '',
    isError: false,
  },
  isShowHeader: true,
  openModelWarning: false,
  textModelWarning: '',
};

export const ComponentsSlice = createSlice({
  name: 'componentsState',
  initialState,
  reducers: {
    handleOpenModelWarning: (state, action) => {
      state.openModelWarning = action.payload.open;
      state.textModelWarning = action.payload.text;
    },
    handleChangeActiveData: (state, action) => {
      state.activeData = action.payload;
    },
    changeActiveSwitch: (state, action) => {
      state.activeSwitch = action.payload;
    },
    handleIsShowHeader: (state, action) => {
      state.isShowHeader = action.payload;
    },
    handleIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    handleIsError: (state, action) => {
      state.error.isError = action.payload.isError;
      state.error.code = action.payload.code;
      state.error.message =
        action.payload.message || 'При загрузке данных произошла ошибка.\nСервер недоступен.\nПовторите попытку позже.';
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(authApi.endpoints.authUser.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(authApi.endpoints.authUser.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(authApi.endpoints.authUser.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(benefitsDataApi.endpoints.getBenefitsList.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(benefitsDataApi.endpoints.getBenefitsList.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(benefitsDataApi.endpoints.getBenefitsList.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(meetingsApi.endpoints.updateFakeMeeting.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(meetingsApi.endpoints.updateFakeMeeting.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(meetingsApi.endpoints.updateFakeMeeting.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(meetingsApi.endpoints.updateMeetingData.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(meetingsApi.endpoints.updateMeetingData.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(meetingsApi.endpoints.updateMeetingData.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(presentationApi.endpoints.getPresentation.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(presentationApi.endpoints.getPresentation.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(presentationApi.endpoints.getPresentation.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(contactsApi.endpoints.fetchContact.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(contactsApi.endpoints.fetchContact.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(contactsApi.endpoints.fetchContact.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(contactsApi.endpoints.fetchAllContacts.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(contactsApi.endpoints.fetchAllContacts.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(contactsApi.endpoints.fetchAllContacts.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(contactsApi.endpoints.fetchTemplates.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(contactsApi.endpoints.fetchTemplates.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(contactsApi.endpoints.fetchTemplates.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(contactsApi.endpoints.sendWabaTemplate.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(contactsApi.endpoints.sendWabaTemplate.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(contactsApi.endpoints.sendWabaTemplate.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(contactsApi.endpoints.fetchDeal.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(contactsApi.endpoints.fetchDeal.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(contactsApi.endpoints.fetchDeal.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(contactsApi.endpoints.fetchAllDeals.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(contactsApi.endpoints.fetchAllDeals.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(contactsApi.endpoints.fetchAllDeals.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(contactsApi.endpoints.sendDealsTemplate.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(contactsApi.endpoints.sendDealsTemplate.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(contactsApi.endpoints.sendDealsTemplate.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(bookingApi.endpoints.generateClientBookingLink.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(bookingApi.endpoints.generateClientBookingLink.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(bookingApi.endpoints.generateClientBookingLink.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(formApi.endpoints.getFormData.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(formApi.endpoints.getFormData.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(formApi.endpoints.getFormData.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(formApi.endpoints.postFormData.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(formApi.endpoints.postFormData.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(formApi.endpoints.postFormData.matchRejected, state => {
        state.isLoading = false;
      })

      .addMatcher(meetingsApi.endpoints.finishMeeting.matchFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(meetingsApi.endpoints.finishMeeting.matchPending, state => {
        state.isLoading = true;
      })
      .addMatcher(meetingsApi.endpoints.finishMeeting.matchRejected, state => {
        state.isLoading = false;
      });
  },
});

export const {
  handleOpenModelWarning,
  handleChangeActiveData,
  handleIsLoading,
  changeActiveSwitch,
  handleIsShowHeader,
  handleIsError,
} = ComponentsSlice.actions;

export const activeSwitchSelector = () => state => {
  return state.componentsState.activeSwitch;
};

export const loadingStatus = () => state => {
  return state.componentsState.isLoading;
};

export const errorStatus = () => state => {
  return state.componentsState.error.isError;
};

export default ComponentsSlice.reducer;
