import { useEffect, useState } from 'react';

export const useSelectMortgageGroup = (mortgagesList, uniqueSuffix) => {
  const benefitGroupName = `currBenefitGroup${uniqueSuffix}`;
  const [benefitGroup, setBenefitGroup] = useState(sessionStorage.getItem(benefitGroupName) || '');
  const [groups, setGroups] = useState([]);

  const handleChangeBenefitGroup = value => {
    setBenefitGroup(value);
    sessionStorage.setItem(benefitGroupName, value);
  };

  useEffect(() => {
    const benefitGroupFromSS = sessionStorage.getItem(benefitGroupName);
    if (benefitGroupFromSS && benefitGroupFromSS !== 'undefined') {
      setBenefitGroup(benefitGroupFromSS);
    }
  }, [benefitGroupName]);

  useEffect(() => {
    if (mortgagesList.length > 0) {
      const arr = [];
      mortgagesList.forEach(i => {
        if (
          !arr.some(benefit => {
            return benefit.group === i.group;
          })
        ) {
          arr.push(i);
        }
      });
      setGroups(arr);
    }
  }, [mortgagesList]);
  return {
    benefitGroup: { value: benefitGroup || '', id: benefitGroup || '', title: benefitGroup || '' },
    handleChangeBenefitGroup,
    groups,
  };
};
