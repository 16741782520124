import { useEffect, useMemo, useState } from 'react';

export const useSelectFlat = (flats, bulks, { section, bulk, house }, currProject, uniqueSuffix) => {
  const flatName = `currFlat${uniqueSuffix}`;
  const foundBulk = bulk ? bulks.find(i => bulk === i.name) : null;
  const foundHouse = house ? bulks.find(i => house === i.houseName) : null;
  const [flatNumber, setFlatNumber] = useState(sessionStorage.getItem(flatName));
  const handleChangeFlat = value => {
    setFlatNumber(value);
    sessionStorage.setItem(flatName, value);
  };

  const houseIds = useMemo(() => {
    if (!foundHouse) return [];
    return bulks
      .filter(i => i.houseName === foundHouse?.houseName)
      .map(item => {
        return item.id;
      });
  }, [bulks, foundHouse]);

  const flatsNumbers = useMemo(() => {
    if (!flats) {
      return [];
    }

    if (section && currProject?.id === 1) {
      return flats
        .filter(i => Number(i.section.number) === Number(section))
        .map(flat => {
          return String(flat.number);
        });
    }

    if (foundBulk) {
      return flats
        .filter(i => foundBulk.id === i.bulk_id)
        .map(flat => {
          return String(flat.number);
        });
    }

    if (foundHouse) {
      return flats
        .filter(i => houseIds.includes(i.bulk_id))
        .map(flat => {
          return String(flat.number);
        });
    }

    return flats.map(flat => {
      return String(flat.number);
    });
  }, [flats, section, currProject?.id, foundBulk, foundHouse, houseIds, uniqueSuffix, bulks]);

  useEffect(() => {
    const flatFromSS = sessionStorage.getItem(flatName);
    if (flatFromSS && flatFromSS !== 'undefined') {
      setFlatNumber(flatFromSS);
    }
  }, [flatName]);

  return {
    flatsNumbers,
    flatNumber,
    handleChangeFlat,
    flat: flats.find(flat => String(flat.number) === String(flatNumber)),
  };
};
