import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { calcDataLotSelector, initialPaymentLotSelector } from '../../entities/ProjectSlice';
import style from './MortgageColumn.module.scss';

function MortgageColumn() {
  const initialPaymentLot = useSelector(initialPaymentLotSelector());
  const calcDataLot = useSelector(calcDataLotSelector());

  return (
    <Stack className={style.wrapper}>
      <Box sx={{ width: '100%' }} className={style.estimationItem}>
        <Typography component='div' variant='h6' className={style.estimaionItemHeading}>
          Сумма кредита
        </Typography>
        <Typography className={style.estimaionItemSum}>
          {new Intl.NumberFormat('ru-RU').format(
            Number.parseInt(calcDataLot.amountOfCredit ?? initialPaymentLot.amountOfCredit),
          )}{' '}
          ₽
        </Typography>
      </Box>

      <Box sx={{ width: '100%' }} className={style.estimationItem}>
        <Typography component='div' variant='h6' className={style.estimaionItemHeading}>
          Ежемесячный платеж
        </Typography>
        <Typography className={style.estimaionItemSum}>
          {new Intl.NumberFormat('ru-RU').format(
            Number.parseInt(calcDataLot.monthlyPayment ?? initialPaymentLot.monthlyPayment),
          )}{' '}
          ₽
        </Typography>
      </Box>
    </Stack>
  );
}
export default MortgageColumn;
