import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { apiRoutes } from './api/api';

const initialState = {
  display: 'flats',
};

export const FavoritesSlice = createSlice({
  name: 'favoritesData',
  initialState,
  reducers: {
    setDisplayProgramsOrFlats: (state, action) => {
      localStorage.setItem('savedListFavorites', action.payload);
      state.display = action.payload;
    },
  },
});

export const favoritesApi = createApi({
  reducerPath: 'favoritesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getFavoritesFlats: builder.query({
      query: meetingId => `${apiRoutes.favoriteFlats.replace(':meetingId', meetingId)}`,
      providesTags: [{ type: 'FavoritesFlats', id: 'LIST' }],
    }),
    addFavoriteFlat: builder.mutation({
      query: ({ meetingId, flatId, data }) => ({
        url: `${apiRoutes.favoriteFlat.replace(':meetingId', meetingId).replace(':flatId', flatId)}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'FavoritesFlats', id: 'LIST' }],
    }),
    deleteFavoriteFlat: builder.mutation({
      query: ({ meetingId, flatId }) => ({
        url: `${apiRoutes.favoriteFlat.replace(':meetingId', meetingId).replace(':flatId', flatId)}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'FavoritesFlats', id: 'LIST' }],
    }),

    //программы из сравнения
    getFavoritesProgrammsFlats: builder.query({
      query: meetingId => `${apiRoutes.favoritesComparedFlat.replace(':meetingId', meetingId)}`,
      providesTags: [{ type: 'ProgramsFlats', id: 'LISTCOMPARE' }],
    }),

    addFavoriteProgrammsFlat: builder.mutation({
      query: ({ meetingId, comparedId }) => ({
        url: `${apiRoutes.changeFavoritesComparedFlat
          .replace(':meetingId', meetingId)
          .replace(':comparedId', comparedId)}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'ProgramsFlats', id: 'LISTCOMPARE' }],
    }),

    deleteFavoriteProgrammsFlat: builder.mutation({
      query: ({ meetingId, comparedId }) => ({
        url: `${apiRoutes.changeFavoritesComparedFlat
          .replace(':meetingId', meetingId)
          .replace(':comparedId', comparedId)}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'ProgramsFlats', id: 'LISTCOMPARE' }],
    }),
  }),
});

export const { setDisplayProgramsOrFlats } = FavoritesSlice.actions;
export default FavoritesSlice.reducer;
export const {
  useGetFavoritesFlatsQuery,
  useAddFavoriteFlatMutation,
  useDeleteFavoriteFlatMutation,
  useAddFavoriteProgrammsFlatMutation,
  useDeleteFavoriteProgrammsFlatMutation,
  useGetFavoritesProgrammsFlatsQuery,
} = favoritesApi;
