import axios from 'axios';
import { DOMAIN_RELATIVE } from './constants';

export const actionLogging = (data, meetingId) => {
  axios.post(
    `${DOMAIN_RELATIVE}/api/meetings/${meetingId}/logger/action`,
    {
      action: data,
      date: Date.now().toString(),
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );
};

export const stageLogging = (data, meetingId) => {
  axios.post(
    `${DOMAIN_RELATIVE}/api/meetings/${meetingId}/logger/stage`,
    {
      action: data,
      date: Date.now().toString(),
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );
};
