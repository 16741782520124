import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Stack, styled } from '@mui/system';
import { useEffect } from 'react';
import { handleBodyLock, handleBodyUnlock } from '../../utils/utils';

const Loader = () => {
  useEffect(() => {
    handleBodyLock();
    return () => {
      handleBodyUnlock();
    };
  }, []);

  return (
    <StyledBox>
      <StyledStack spacing={2}>
        <LinearProgress sx={{ width: '100%', height: '4px' }} color='secondary' />
        <StyledTypography component='div' color='secondary'>
          ЗАГРУЗКА
        </StyledTypography>
      </StyledStack>
    </StyledBox>
  );
};

export default Loader;

//STYLES
const StyledBox = styled(Box)({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 99999,
  background: 'rgba(245, 245, 245, 0.7)',
});

const StyledStack = styled(Stack)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '50% !important',
  maxWidth: '500px',
  height: '30% !important',
  position: 'absolute !important',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const StyledTypography = styled(Typography)({
  fontSize: '15px !important',
  lineHeight: '26px',
});
