import { useCallback, useEffect, useState } from 'react';
import { actionLogging } from '../../../utils/loggers';

export const useSwitchCalculatorTab = (uniqueId, isEqualizer, meetingId) => {
  const activeTabName = `activeTab${uniqueId}`;
  const [activeTab, setActiveTab] = useState(sessionStorage.getItem(activeTabName) || 'lot');

  const handleChangeActiveTab = useCallback(
    value => {
      setActiveTab(value);
      sessionStorage.setItem(activeTabName, value);
      if (isEqualizer) {
        const type = value === 'price' ? 'расчет по прайс-листу' : 'расчет по лоту';
        actionLogging(`Перешли на раздел эквалайзера "${type}"`, meetingId);
      }
    },
    [activeTabName, isEqualizer, meetingId],
  );

  useEffect(() => {
    const activeTabFromSS = sessionStorage.getItem(activeTabName);
    if (activeTabFromSS && activeTabFromSS !== 'undefined') {
      handleChangeActiveTab(activeTabFromSS);
    }
  }, [activeTabName, handleChangeActiveTab]);

  return { handleChangeActiveTab, activeTab };
};
