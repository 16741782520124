import { Box } from '@mui/material';
import style from './ErrorBlock.module.scss';

const ErrorBlock = ({ isRecalculateWarning }) => {
  const handleFunction = () => {
    window.location.reload();
  };

  const errorText = isRecalculateWarning
    ? 'Произведите перерасчет, данные на сервере могут отличаться от представленных.'
    : 'Расчёт программ временно недоступен из-за проблем на сервере. Для расчёта программ попробуйте обновить\n' +
      '          страницу или зайдите позже.';

  return (
    <Box className={style.wrapper}>
      <svg
        className={style.icon}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.08398 12.75H10.9173V14.5833H9.08398V12.75ZM9.08398 5.41667H10.9173V10.9167H9.08398V5.41667ZM9.99148 0.833334C4.93148 0.833334 0.833984 4.94 0.833984 10C0.833984 15.06 4.93148 19.1667 9.99148 19.1667C15.0607 19.1667 19.1673 15.06 19.1673 10C19.1673 4.94 15.0607 0.833334 9.99148 0.833334ZM10.0007 17.3333C5.94898 17.3333 2.66732 14.0517 2.66732 10C2.66732 5.94833 5.94898 2.66667 10.0007 2.66667C14.0523 2.66667 17.334 5.94833 17.334 10C17.334 14.0517 14.0523 17.3333 10.0007 17.3333Z'
          fill='white'
        />
      </svg>

      <div className={style.text}>
        <p className={style.title}>Ошибка</p>
        <p className={style.message}>{errorText}</p>
      </div>

      {!isRecalculateWarning && (
        <button className={style.button} onClick={handleFunction}>
          обновить
        </button>
      )}
    </Box>
  );
};

export default ErrorBlock;
