import { useRef } from 'react';

export const useDebouncedFunction = (func, delay) => {
  const timeoutRef = useRef();

  function clearTimer() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }

  return (...args) => {
    clearTimer();
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
