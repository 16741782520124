import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.jsx';
import { loggingBySW } from './utils/feature-flags.js';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/ru';

import './utils/polyfills.js';

if ('serviceWorker' in navigator && loggingBySW) {
  navigator.serviceWorker
    .register(import.meta.env.MODE === 'production' ? '/service-worker.js' : '/dev-sw.js?dev-sw', {
      type: import.meta.env.MODE === 'production' ? 'classic' : 'module',
    })
    .catch(error => console.error(error));
}

if (
  import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production' ||
  import.meta.env.VITE_SENTRY_ENVIRONMENT === 'preflight'
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    debug: false,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
}

ReactDOM.createRoot(document.querySelector('#root')).render(
  <React.StrictMode>
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale='ru'
      localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LocalizationProvider>
  </React.StrictMode>,
);
