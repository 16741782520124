import { useCallback, useState } from 'react';

export const useGetFetchStatus = () => {
  const [fetchStatus, setFetchStatus] = useState('loaded');

  const handleSetPendingStatus = useCallback(() => {
    setFetchStatus('pending');
  }, []);

  const handleSetLoadedStatus = useCallback(() => {
    setFetchStatus('loaded');
  }, []);

  const handleSetErrorStatus = useCallback(() => {
    setFetchStatus('error');
  }, []);

  return { fetchStatus, handleSetErrorStatus, handleSetLoadedStatus, handleSetPendingStatus };
};
