import ROUTES from '../entities/api/routes';

export const isPartnerRedirect = () => {
  return window.location.search.includes(ROUTES.redirect.partner);
};

export const partnerRedirectHandler = () => {
  const isRedirect = isPartnerRedirect();
  const currentPath = window.location.pathname;
  if (isRedirect && currentPath !== ROUTES.root) {
    window.location.href = `${ROUTES.root}?redirect_url=${ROUTES.redirect.partner}`;
  }
};
