import { TextField } from '@mui/material';
import { Colors } from '../../../assets/mui-styles/Colors';
import { useDebouncedFunction } from '../../../hooks/useDebouncedFunction';

export const CustomInput = ({
  error,
  label,
  valueInput,
  inputType,
  helperText,
  onChangeInput,
  inputComponent,
  endAdornment,
  fetchStatus,
}) => {
  const changeInputHandler = useDebouncedFunction(onChangeInput, 700);
  return (
    <TextField
      error={error}
      label={label}
      color='secondary'
      sx={{ backgroundColor: Colors.primary, width: '100%' }}
      value={valueInput}
      type={inputType}
      helperText={helperText}
      onChange={e => {
        if (fetchStatus === 'pending') return;
        const value = Number(e.target.value.replaceAll(' ', '').replaceAll('%', '').replaceAll('₽', ''));
        changeInputHandler(value);
      }}
      variant='outlined'
      name='numberformat'
      id='formatted-numberformat-input'
      InputProps={{
        inputComponent,
        endAdornment,
        readOnly: fetchStatus === 'pending',
      }}
    />
  );
};
