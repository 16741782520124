import { DOMAIN_RELATIVE } from '../../../utils/constants';
import { clearUserData } from '../../../utils/utils';

export const fetchFlats = async url => {
  const response = await fetch(url, {
    method: 'GET',
  });
  return response.json();
};

export const fetchMortgagesPrice = async id => {
  const response = await fetch(`${DOMAIN_RELATIVE}/api/v2/flat/mortgage/list?blockId=${id}`, {
    method: 'GET',
  });

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

export const fetchBenefits = async ({ flatId, initialPayment, loanTerm, benefitId }) => {
  const response = await fetch(
    `https://api.forma.ru/v2/flat/mortgage?flatId=${flatId}&initialPayment=${initialPayment}&loanTerm=${loanTerm}&benefitId=${benefitId}`,
    {
      method: 'GET',
    },
  );

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

export const fetchInitialPayment = async ({ currBenefitLot, flatPrice }) => {
  const response = await fetch(
    `${DOMAIN_RELATIVE}/api/flat/mortgage/initialPaiment?mortgageId=${currBenefitLot}&sum=${flatPrice}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

export const fetchCalcData = async ({ loanTerm, mortgageId, initialPayment, sum }) => {
  const response = await fetch(
    `${DOMAIN_RELATIVE}/api/v2/mortgage/flat?loanTerm=${loanTerm}&mortgageId=${mortgageId}&initialPayment=${initialPayment}&sum=${sum}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

export const fetchLotCalcData = async ({ loanTerm, mortgageId, initialPayment, sum }) => {
  const response = await fetch(
    `${DOMAIN_RELATIVE}/api/flat/mortgage?loanTerm=${loanTerm}&mortgageId=${mortgageId}&initialPayment=${initialPayment}&sum=${sum}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};
