import { useCalculatorLogicForLot } from '../../widgets/calculator/CalculatorLogicForLot';
import { useCalculatorLogicForPrice } from '../../widgets/calculator/CalculatorLogicForPrice';
import { useGetFetchStatus } from '../../widgets/calculator/calculator-logic/useGetFetchStatus';
import { CalculatorBlock } from './calculator-block/CalculatorBlock';

export function CalculatorPageNew() {
  const { fetchStatus, ...statusSetters } = useGetFetchStatus();
  const propsForCalculatorByLot = useCalculatorLogicForLot('Lot', statusSetters);
  const propsForCalculatorByPrice = useCalculatorLogicForPrice('Price', statusSetters);
  return (
    <CalculatorBlock
      propsForCalculatorByLot={propsForCalculatorByLot}
      propsForCalculatorByPrice={propsForCalculatorByPrice}
      uniqueId='Calculator'
      fetchStatus={fetchStatus}
      statusSetters={statusSetters}
    />
  );
}
