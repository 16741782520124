import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userRolesSelector } from '../../entities/AuthSlice';
import RoleCard from '../../features/role-card/RoleCard';
import { USER_TOOLS } from '../../utils/constants';
import ModalAuth from '../../widgets/modal-auth/ModalAuth';
import style from './AuthPage.module.scss';

const userRolesDefault = USER_TOOLS.map(i => i.role);

const AuthPage = () => {
  const [userRoleMatch, setUserRoleMatch] = useState();

  const user = JSON.parse(localStorage.getItem('user'));
  const isUser = Boolean(user?.id && user.firstName && user.email);
  const userStatusValid =
    import.meta.env.VITE_BLOCKED_AGENCY_DISPLAY_19451 === 'true' ? user?.agencyStatus !== 'blocked' : true;
  const userRoles = useSelector(userRolesSelector);

  useEffect(() => {
    if (!userRoles || userRoles.length === 0) return;

    const userRole = userRolesDefault.filter(i => {
      if (Array.isArray(i)) {
        return userRoles.some(role => i.includes(role));
      }
      return userRoles.includes(i);
    });

    setUserRoleMatch(userRole);
  }, [userRoles]);

  if (!isUser) {
    return <ModalAuth />;
  }

  return (
    <div className={style.content}>
      <div className={style.container}>
        <h1 className={style.title}>Выбор инструмента</h1>

        {userRoleMatch && userRoleMatch.length === 0 && !userRoles.includes('admin') && (
          <div className={style.plug}>Для вашей роли нет доступных инструментов</div>
        )}

        {((userRoleMatch && userRoleMatch.length > 0) || userRoles.includes('admin')) && userStatusValid && (
          <div className={style.links}>
            {USER_TOOLS.map(i => {
              return <RoleCard key={i.id} item={i} onlyMobile={i.onlyMobile} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
