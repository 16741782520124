export const CloseIcon = props => {
  return (
    <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.5074 1.41L13.0807 0L7.42479 5.59L1.76883 0L0.342194 1.41L5.99815 7L0.342194 12.59L1.76883 14L7.42479 8.41L13.0807 14L14.5074 12.59L8.85143 7L14.5074 1.41Z'
        fill='#333130'
      />
    </svg>
  );
};
