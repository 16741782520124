// выбор section/house/bulk
import { useEffect, useState } from 'react';

export const useSelectBuilding = buildingSuffix => {
  const buildingName = `curr${buildingSuffix}`;
  const [building, setBuilding] = useState(sessionStorage.getItem(buildingName));

  const handleChangeBuilding = newBuilding => {
    setBuilding(newBuilding);
    sessionStorage.setItem(buildingName, newBuilding);
  };

  useEffect(() => {
    const buildingFromSS = sessionStorage.getItem(buildingName);
    if (buildingFromSS && buildingFromSS !== 'undefined') {
      setBuilding(buildingFromSS);
    }
  }, [buildingName]);

  return { building, handleChangeBuilding };
};
