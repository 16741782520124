import { Box, Container, Stack } from '@mui/material';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Colors } from '../../../assets/mui-styles/Colors';
import { deviceSelector } from '../../../entities/AuthSlice';
import Loader from '../../../shared/loader/Loader';
import ErrorBlock from '../../../widgets/error-block/ErrorBlock';
import { CalculatorTabs } from '../calculator-tabs/CalculatorTabs';
import { useSwitchCalculatorTab } from '../calculator-tabs/useSwitchCalculatorTab';
import { EstimationBlock } from '../estimation-block/EstimationBlock';
import style from './CalculatorBlock.module.scss';

export const CalculatorBlock = ({
  propsForCalculatorByPrice,
  propsForCalculatorByLot,
  fetchStatus,
  statusSetters,
  uniqueId,
  meetingId,
  isEqualizer,
}) => {
  const { activeTab, handleChangeActiveTab } = useSwitchCalculatorTab(uniqueId, isEqualizer, meetingId);
  const deviceInfo = useSelector(deviceSelector());
  return (
    <>
      {fetchStatus === 'pending' && <Loader />}
      {fetchStatus === 'error' && <ErrorBlock />}
      <Container
        sx={{
          minWidth: '100%',
          backgroundColor: Colors.primary,
          minHeight: '100vh',
        }}
        className={style.container}
      >
        <Stack className={style.generalPageStack}>
          <CalculatorTabs
            meetingId={meetingId}
            propsForCalculatorByLot={propsForCalculatorByLot}
            propsForCalculatorByPrice={propsForCalculatorByPrice}
            statusSetters={statusSetters}
            handleChangeActiveTab={handleChangeActiveTab}
            activeTab={activeTab}
            isEqualizer={isEqualizer}
            fetchStatus={fetchStatus}
          />

          {deviceInfo.device !== 'isMobile' && (
            <>
              {propsForCalculatorByLot.priceWithMargin > 0 &&
                propsForCalculatorByLot.minFirstPayment < propsForCalculatorByLot.maxFirstPayment &&
                propsForCalculatorByLot.benefit &&
                propsForCalculatorByLot.term >= propsForCalculatorByLot.minTerm &&
                propsForCalculatorByLot.term <= propsForCalculatorByLot.maxTerm &&
                propsForCalculatorByLot.firstPaymentCommitted <= propsForCalculatorByLot.maxFirstPayment &&
                propsForCalculatorByLot.firstPaymentCommitted >= propsForCalculatorByLot.minFirstPayment &&
                (isEqualizer ? !propsForCalculatorByLot.isDefaultCalculation : true) && (
                  <Box
                    sx={{ height: '100%' }}
                    className={cn({
                      [style.wrapperStealth]: activeTab !== 'lot',
                    })}
                  >
                    <EstimationBlock
                      isEqualizer={isEqualizer}
                      {...propsForCalculatorByLot}
                      fetchStatus={fetchStatus}
                      meetingId={meetingId}
                      isLotTab
                    />
                  </Box>
                )}

              {propsForCalculatorByPrice.calcData?.monthlyPayment &&
                propsForCalculatorByPrice.initialPayment?.priceWithMargin > 0 &&
                propsForCalculatorByPrice.minFirstPayment < propsForCalculatorByPrice.maxFirstPayment &&
                Number(propsForCalculatorByPrice.flatPrice) !== 0 &&
                propsForCalculatorByPrice.benefit &&
                propsForCalculatorByPrice.term >= propsForCalculatorByPrice.minTerm &&
                propsForCalculatorByPrice.term <= propsForCalculatorByPrice.maxTerm &&
                propsForCalculatorByPrice.firstPaymentCommitted <= propsForCalculatorByPrice.maxFirstPayment &&
                propsForCalculatorByPrice.firstPaymentCommitted >= propsForCalculatorByPrice.minFirstPayment && (
                  <Box
                    sx={{ height: '100%' }}
                    className={cn({
                      [style.wrapperStealth]: activeTab !== 'price',
                    })}
                  >
                    <EstimationBlock {...propsForCalculatorByPrice} fetchStatus={fetchStatus} meetingId={meetingId} />
                  </Box>
                )}
            </>
          )}
        </Stack>
      </Container>
    </>
  );
};
