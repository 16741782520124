import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { errorStatus, useAuthUserMutation } from '../../entities/AuthSlice';
import InputLogin from '../../features/input-login/InputLogin';
import InputPassword from '../../features/input-password/InputPassword';
import { handleBodyLock } from '../../utils/utils';
import style from './ModalAuth.module.scss';
import { useLocation } from 'react-router-dom';

const ModalAuth = () => {
  handleBodyLock();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const formRef = useRef(null);

  const [authUser] = useAuthUserMutation();
  const error = useSelector(errorStatus());

  const user = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();
  const redirectLink = location.search?.replace('?redirect_url=', '');
  const isRedirect = user && user?.redirectUrl === redirectLink;

  const handleAuthUser = e => {
    e?.preventDefault();
    const email = login;
    authUser(JSON.stringify({ email, password })).then(() => {
      if (isRedirect) window.location.replace(redirectLink);
    });
  };

  useEffect(() => {
    //при клике на  'Enter' и отсутствии фокуса у обоих полей формы
    //вызываем onSubmit и при этом блокируем дефолтное поведение формы вызовом кнопки submitBtnRef.current
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        formRef.current.requestSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <Box className={style.modalAuthContainer}>
      <Card className={style.modalAuthCard}>
        <Stack
          as='form'
          ref={formRef}
          onSubmit={e => handleAuthUser(e)}
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={style.modalAuthStack}
          onKeyDown={e => {
            e.stopPropagation();
          }}
        >
          <Typography component='div' variant='h4' sx={{ fontWeight: 600 }} color='secondary'>
            Авторизация
          </Typography>
          {error.message === 'not valid email or password' && (
            <Typography component='div' variant='div' sx={{ fontWeight: 400 }} color='error'>
              Неверный логин или пароль
            </Typography>
          )}
          {error.isError && error.message !== 'not valid email or password' && (
            <Typography component='div' variant='div' sx={{ fontWeight: 400 }} color='error'>
              Что-то пошло не так. Попробуйте еще раз.
            </Typography>
          )}
          <InputLogin value={login} setValue={setLogin} />
          <InputPassword value={password} setValue={setPassword} />
          <Button className={style.ButtonFullBtn} type='submit' variant='contained' color='secondary'>
            Войти
          </Button>
        </Stack>
      </Card>
    </Box>
  );
};

export default ModalAuth;
