import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTab: 1,
  activeSort: {
    id: null,
    direction: true,
  },
  uniqueBenefits: [],
};

export const MonitorSlice = createSlice({
  name: 'monitorInfo',
  initialState,
  reducers: {
    //изменение активной вкладки
    changeActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    //изменение активной сортировки
    changeActiveSort: (state, action) => {
      if (state.activeSort.id === action.payload) {
        state.activeSort.direction = !state.activeSort.direction;
      } else {
        state.activeSort.id = action.payload;
        state.activeSort.direction = true;
      }
    },

    //изменение массива уникальных бенефитов
    changeUniqueBenefits: (state, action) => {
      const uniqueBenefitsCash = action.payload?.filter(i => i.group === 'cash');
      const uniqueBenefitsMortgage = action.payload?.filter(i => i.group === 'mortgage');
      const uniqueBenefitsInstallment = action.payload?.filter(i => i.group === 'installment');
      const uniqueBenefitsSorted = uniqueBenefitsCash.concat(uniqueBenefitsMortgage).concat(uniqueBenefitsInstallment);
      state.uniqueBenefits = uniqueBenefitsSorted;
    },
  },
});

export const { changeActiveTab, changeActiveSort, changeUniqueBenefits } = MonitorSlice.actions;

//активная сортировка
export const activeSortSelector = state => {
  const res = state.monitorInfo.activeSort || {};
  return res;
};

//массив всех уникальных бенефитов
export const uniqueBenefitsSelector = state => {
  const res = state.monitorInfo.uniqueBenefits || [];
  return res;
};

export default MonitorSlice.reducer;
