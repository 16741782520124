const ROUTES = {
  root: '/',
  calculator: '/calculator',
  monitor: '/monitor',
  lotSelection: '/lot-selection',
  benefits: '/benefits',
  booking: '/booking',
  forbidden: '/forbidden',
  meetingsMonitor: '/monitor-meetings',
  accreditation: '/acred',
  accreditationApplication: '/acred/:userId',

  partner: {
    root: '/partner-admin',
    partnerShow: '/partner-admin/:id/show',
    partnerChange: '/partner-admin/:id',
    partner_created: '/partner-admin/created',
  },

  waba: {
    root: '/waba',
    leads: '/waba/leads',
    lead: '/waba/leads/:id',
    deals: '/waba/deals',
    deal: '/waba/deals/:id',
  },

  meetingSchedule: '/meeting-schedule',

  lk: {
    root: '/lk/*',
    plainRoot: '/lk/',
    plainRootAlt: '/lk',
    meetings: 'meetings',
    favorites: 'favorites',
    form: 'form',
    equalizer: 'equalizer',
    presentation: 'presentation',
    final: 'final',
    map: 'map',

    lkProjectsWorkplace: 'projects/commercial',
    lkListWorkplace: 'projects/commercial/:name',
    workplace: 'projects/commercial/:name/:flatId',

    lkProjects: 'projects',
    lkList: 'projects/:name',
    flat: 'projects/:name/:flatId',
  },

  redirect: {
    partner: 'https://partner.forma.ru/',
  },
};

export default ROUTES;
