import { Card, CardContent, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { PrimaryColors } from '../../../assets/mui-styles/Colors';
import { initialProjects } from '../../../widgets/calculator/calculator-logic/initialProjects';
import style from './EqualizerFlatCard.module.scss';

export const EqualizerFlatCardNew = ({
  flat,
  activeProjectId,
  handleChangeActiveProjectId,
  projectHasChanged,
  flatData,
  handleChangeFlat,
  handleChangeSection,
  handleChangeHouse,
  handleChangeBulk,
  handleChangeBenefit,
}) => {
  const currFlatIdFavourite = sessionStorage.getItem('flatFavouriteEqId');
  const project = initialProjects.filter(i => flat.block_id === i.blockId)[0] || [];
  const isSelected = (flatData?.id === flat.id || currFlatIdFavourite === flat.id) && project.value === activeProjectId;

  const handleClick = () => {
    sessionStorage.setItem('flatFavouriteEq', flat.number);
    sessionStorage.setItem('flatFavouriteEqId', flat.id);
    sessionStorage.setItem('projectFavouriteEq', project.value);
    sessionStorage.setItem('activeProjectEq', project.value);

    // сбрасываем программу при смене лота
    handleChangeBenefit();

    if (activeProjectId !== project.value) {
      handleChangeActiveProjectId(project.value);
      projectHasChanged();
    }

    if (project.value === '1') {
      handleChangeSection(String(flat.section.number));
    } else {
      handleChangeBulk(flat.bulk.name);
      handleChangeHouse(flat.bulk.houseName);
    }

    handleChangeFlat(flat.number);
  };

  const textForm = num => {
    if (num === 1) {
      return `${num} спальня`;
    }
    if (num >= 2 && num < 5) {
      return `${num} спальни`;
    }
    if (num === 'studio') {
      return 'студия';
    }
    return `${num} спален`;
  };

  return (
    <Card
      onClick={handleClick}
      sx={{ borderColor: isSelected ? PrimaryColors.primary : PrimaryColors.border }}
      className={style.card}
    >
      <CardContent className={style.cardContent}>
        {flat.flat_plan_png && (
          <Box className={style.imgWrapper}>
            <img src={flat.flat_plan_png} alt='' className={style.img} />
          </Box>
        )}

        <Stack direction='column'>
          {flat.number && (
            <Typography variant='body1' color='primary' className={style.text}>
              №{flat.number ? flat.number : ''}
            </Typography>
          )}
          {flat.bedrooms && flat.area && (
            <Typography variant='subtitle1' color='primary' mt='-2px' className={style.text}>
              {textForm(flat.bedrooms)} {flat.area} м
              <sup>
                <small>2</small>
              </sup>
            </Typography>
          )}
          {project.title && (
            <Typography variant='subtitle2' color='primary' mt='2px'>
              {project.title}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
