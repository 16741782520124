import { AnimatePresence } from 'framer-motion';
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { defineDevice, defineUserRoles, deviceSelector } from '../entities/AuthSlice';
import { activeSwitchSelector, errorStatus, handleIsError, loadingStatus } from '../entities/ComponentsSlice';
import { fetchStatusSelector as fetchStatusSelectorEq } from '../entities/EqualizerSlice';
import { toggleHeader } from '../entities/MeetingsSlice';
import { estimateModalStatusSelector, fetchStatusSelector, warningBenefitSelector } from '../entities/ProjectSlice';
import Loader from '../shared/loader/Loader';
import { clearCalculatorStorage, clearUserData, handleBodyUnlock } from '../utils/utils';
import Header from '../widgets/header/Header';
import ModalEstimationPrice from '../widgets/modal-estimation-price/ModalEstimationPrice';
import ModalEstimation from '../widgets/modal-estimation/ModalEstimation';
import ModalWarning from '../widgets/modal-warning/ModalWarning';
import { checkAuth } from '../utils/checkAuth.js';
import ModalError from '../widgets/modal-error/ModalError.jsx';
import axios from 'axios';
import { displayProjectsByRole, showErrorModalOnNetworkErrors } from '../utils/feature-flags.js';
import { fetchProjectsByRole } from '../entities/api/api.js';
import InternetConnectionFailModal from '../widgets/internet-connection-fail/InternetConnectionFailModal.jsx';
import { defaultCalculatorState } from '../utils/defaultCalculatorState.js';
import { isPartnerRedirect } from '../utils/partnerRedirectHandler.js';
import useOnlineStatus from '../hooks/useOnlineStatus.js';

const Layout = ({ vendor, width, userAgent, height }) => {
  useOnlineStatus();
  const user_roles = localStorage.getItem('user_roles');
  const dispatch = useDispatch();
  const deviceInfo = useSelector(deviceSelector());
  const modalStatus = useSelector(estimateModalStatusSelector());
  const activeSwitch = useSelector(activeSwitchSelector());
  const warningBenefit = useSelector(warningBenefitSelector());

  const catalogFetchStatus = useSelector(state => state.catalogPage.urls.getResidential.status);
  const openModelWarning = useSelector(state => state.componentsState.openModelWarning);
  const fetchStatus = useSelector(fetchStatusSelector());
  const fetchStatusEq = useSelector(fetchStatusSelectorEq());
  const isShowHeader = useSelector(state => state.componentsState.isShowHeader);
  const isLoadingData = useSelector(loadingStatus());
  const isError = useSelector(errorStatus());
  const isInternetConnected = useSelector(state => state.connectionSlice.isInternetConnected);
  const user = JSON.parse(localStorage.getItem('user'));
  const redirectLink = location.search?.replace('?redirect_url=', '');
  const isRedirect = user && user?.redirectUrl === redirectLink;

  useEffect(() => {
    const checkAndRedirect = async () => {
      // Await the completion of checkAuth
      const result = await checkAuth();

      if (isRedirect && result) window.location.replace(redirectLink);
    };

    // Call the async function
    checkAndRedirect();
  }, [isRedirect, redirectLink]);

  useEffect(() => {
    if (displayProjectsByRole) {
      if (!sessionStorage.getItem('lastVisitedPath')) {
        sessionStorage.setItem('lastVisitedPath', window.location.pathname);
      }

      if (
        sessionStorage.getItem('lastVisitedPath') !== window.location.pathname ||
        !sessionStorage.getItem('activeProject')
      ) {
        sessionStorage.setItem('activeProject', '0');
        sessionStorage.setItem('lastVisitedPath', window.location.pathname);
        defaultCalculatorState(dispatch);
      }
    } else {
      sessionStorage.setItem('activeProject', '1');
    }
  }, []);

  useEffect(() => {
    if (fetchStatus === 'load') {
      handleBodyUnlock();
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (width && userAgent) {
      dispatch(defineDevice({ width, userAgent, vendor, height }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, userAgent, vendor, dispatch, height]);

  useEffect(() => {
    const userRolesArray = JSON.parse(user_roles);

    if (userRolesArray) {
      const arr = [];
      for (const i of userRolesArray) {
        if (!arr.includes(i.name)) {
          arr.push(i.name);
        }
      }
      dispatch(defineUserRoles(arr));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_roles, isLoadingData, dispatch]);

  useEffect(() => {
    if (displayProjectsByRole && user_roles) {
      const [{ name: role }] = JSON.parse(user_roles);

      dispatch(fetchProjectsByRole(role));
    }
  }, [user_roles]);

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      sessionStorage.setItem('isPWA', 'true');
    }
  }, []);

  useEffect(() => {
    // При изменении вкладки, включается хедер
    dispatch(toggleHeader({ isShowHeader: true }));
  }, [dispatch]);

  axios.interceptors.request.use(
    config => {
      config.metadata = { startTime: new Date() }; // Сохраняем время начала запроса в метаданных конфигурации
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    response => {
      const url = response?.config?.url;
      const method = response?.config?.method?.toUpperCase();

      const endTime = new Date(); // Получаем время завершения запроса
      const startTime = response.config.metadata.startTime; // Получаем время начала запроса из метаданных
      const elapsedTime = endTime - startTime; // Вычисляем время, затраченное на запрос

      console.log(`${method} ${url} +${elapsedTime}ms`);
      return response;
    },
    error => {
      const newError = { ...error }; // Create a shallow copy of the error object
      newError.stack = undefined; // Remove the 'stack' property from the new error object
      console.log('INTERCEPTOR_ERROR', newError);

      // У checkRole своя проверка на 401
      const isCheckRole = error?.config?.url.includes('checkRole');
      // Проверяем должен ли быть редирект на partner.forma.ru
      const partnerRedirect = isPartnerRedirect();

      if (error.response && error.response.status === 401 && !isCheckRole) {
        partnerRedirect ? clearUserData(false) : clearUserData();
      }

      if (error && !isCheckRole && showErrorModalOnNetworkErrors && !partnerRedirect) {
        dispatch(
          handleIsError({
            isError: true,
            code: error.response?.data?.statusCode,
            message: error.response?.data?.message,
          }),
        );
      }

      // Возвращаем ошибку, чтобы она могла быть обработана дальше
      return Promise.reject(error);
    },
  );

  return (
    <>
      <Suspense fallback={<Loader />}>
        {isShowHeader && <Header />}
        <Outlet />
      </Suspense>
      {openModelWarning && <ModalWarning />}
      <AnimatePresence>
        {deviceInfo.device === 'isMobile' && modalStatus === true && warningBenefit === false && (
          <>
            {activeSwitch === 'lot' && <ModalEstimation />}
            {activeSwitch === 'price' && <ModalEstimationPrice />}
          </>
        )}
      </AnimatePresence>
      {isError && isInternetConnected && <ModalError />}
      {(isLoadingData || fetchStatusEq === 'pending' || fetchStatus === 'pending') && <Loader />}
      {!isInternetConnected && <InternetConnectionFailModal />}
      {(isLoadingData ||
        fetchStatusEq === 'pending' ||
        fetchStatus === 'pending' ||
        catalogFetchStatus === 'pending') && <Loader />}
    </>
  );
};

export default Layout;
