//Утилита, рассчитывающая наибольшее значение в массиве объектов по ключу
const calcTheLargest = (array, key) => {
  const res = [...array];
  let max = 0;

  for (const flat of res) {
    let parameter = key;
    if (key === 'price') {
      parameter = 'currentPrice';
    } else {
      parameter = key;
    }

    if (flat[parameter] > max) {
      max = flat[parameter];
    }
  }

  return max;
};

export default calcTheLargest;
