import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { apiRoutes } from './api/api';

const initialState = {
  bookModalStatus: false,
  bookFlat: null,
  bookModalDate: null,
};

export const bookingApi = createApi({
  reducerPath: 'bookingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getBookedFlats: builder.query({
      query: meetingId => `${apiRoutes.bookedFlats.replace(':meetingId', meetingId)}`,
      providesTags: [{ type: 'BookedFlats', id: 'BOOKING' }],
    }),
    bookFlat: builder.mutation({
      query: ({ meetingId, data }) => ({
        url: `${apiRoutes.bookFlat.replace(':meetingId', meetingId)}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'BookedFlats', id: 'BOOKING' }],
    }),
    updateBookedFlatDate: builder.mutation({
      query: ({ meetingId, flatId, data }) => ({
        url: `${apiRoutes.bookedFlat.replace(':meetingId', meetingId).replace(':flatId', flatId)}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'BookedFlats', id: 'BOOKING' }],
    }),
    removeBookedFlat: builder.mutation({
      query: ({ meetingId, flatId }) => ({
        url: `${apiRoutes.bookedFlat.replace(':meetingId', meetingId).replace(':flatId', flatId)}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'BookedFlats', id: 'BOOKING' }],
    }),
    generateClientBookingLink: builder.mutation({
      query: ({ data }) => ({
        url: DOMAIN_RELATIVE + apiRoutes.generateClientBookingLink,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    }),
    getBulksByBlockId: builder.query({
      query: ({ blockId }) => `${DOMAIN_RELATIVE}/api/bulks?blockId=${blockId}`,
    }),
  }),
});

export const BookSlice = createSlice({
  name: 'bookData',
  initialState,
  reducers: {
    changeBookModalStatus: (state, action) => {
      state.bookModalStatus = action.payload;
    },
    changeBookFlat: (state, action) => {
      state.bookFlat = action.payload;
    },
    changeBookModalDate: (state, action) => {
      state.bookModalDate = action.payload;
    },
  },
});

export const { changeBookModalStatus, changeBookFlat, changeBookModalDate } = BookSlice.actions;

//выбранный переключатель калькуляторов
export const bookModalStatusSelector = () => state => {
  return state.bookData.bookModalStatus;
};

//выбранная квартира в модалке бронирования
export const bookFlatSelector = () => state => {
  return state.bookData.bookFlat;
};

//выбранная дата в календаре бронировки квартиры
export const bookModalDateSelector = () => state => {
  return state.bookData.bookModalDate;
};

export const {
  useGetBookedFlatsQuery,
  useBookFlatMutation,
  useUpdateBookedFlatDateMutation,
  useGenerateClientBookingLinkMutation,
  useGetBulksByBlockIdQuery,
} = bookingApi;

export default BookSlice.reducer;
