import { clearLocalStorageFormData, clearUserData, clearCalculatorStorage } from '../../utils/utils';
import style from './ButtonUnlogin.module.scss';

function ButtonUnlogin({ text }) {
  return (
    <button
      type={'button'}
      className={style.button}
      onClick={() => {
        clearUserData(true);
        clearLocalStorageFormData();
        clearCalculatorStorage();
      }}
    >
      <p className={style.text}>{text}</p>

      <svg className={style.icon} width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path d='M21 12L17 8V11H9V13H17V16L21 12Z' />
        <path d='M5 5H12V3H3V21H12V19H5V5Z' />
      </svg>
    </button>
  );
}

export default ButtonUnlogin;
