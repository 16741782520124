import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { apiRoutes } from './api/api';
import { favoritesNew } from '../utils/feature-flags';

export const comparedFlatsApi = createApi({
  reducerPath: 'comparedFlatsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getComparedFlats: builder.query({
      query: meetingId =>
        `${
          favoritesNew
            ? apiRoutes.comparedFlatsNew.replace(':meetingId', meetingId)
            : apiRoutes.comparedFlats.replace(':meetingId', meetingId)
        }`,
    }),
  }),
});

export const { useGetComparedFlatsQuery } = comparedFlatsApi;
