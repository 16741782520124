import { useEffect, useMemo, useState } from 'react';

export const useSelectBenefit = (currProject, benefits, uniqueSuffix) => {
  const benefitName = `currBenefit${uniqueSuffix}`;
  const [benefitId, setBenefitId] = useState(sessionStorage.getItem(benefitName));
  const [benefitNotFound, setBenefitNotFound] = useState(false);

  const handleChangeBenefit = value => {
    setBenefitId(value);
    sessionStorage.setItem(benefitName, value);
  };

  const benefit = useMemo(() => {
    setBenefitNotFound(false);
    if (benefits?.length > 0 && benefitName && benefitId) {
      const benefit = benefits.find(benefit => {
        return String(benefit.id) === benefitId;
      });

      // если есть id бенефита для поиска, но найденного бенифита по итогу нет, то warning
      if (benefitId && benefitId !== 'undefined' && !benefit) {
        setBenefitNotFound(true);
        sessionStorage.removeItem('currTermLotEqualizer');
        // свистопляска из-за кривых ответов ПИКа, не надо пытаться это понять
        setTimeout(() => {
          handleChangeBenefit();
          setBenefitNotFound(false);
        }, 200);
      }

      if (benefit) {
        setBenefitNotFound(false);
        return {
          value: String(benefit.id),
          title: benefit.name,
          id: String(benefit.id),
          maxYears: benefit.maxYears,
        };
      }
    }
  }, [benefitId, benefits, benefitName, uniqueSuffix]);

  useEffect(() => {
    const benefitIdFromSS = sessionStorage.getItem(benefitName);
    if (benefitIdFromSS && benefitIdFromSS !== 'undefined') {
      setBenefitId(benefitIdFromSS);
    }
  }, [benefitName]);

  return {
    benefitId,
    benefit,
    handleChangeBenefit,
    benefitNotFound,
  };
};
