import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Colors, PrimaryColors } from '../../../assets/mui-styles/Colors';

export const CustomSelect = ({ currentItem, handleChangeCurrentItem, items = [], label = '', isDisabled = false }) => {
  return (
    <Box>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel color='secondary'>{label}</InputLabel>
        <Select
          disabled={isDisabled}
          variant='outlined'
          value={currentItem ? currentItem.value : ''}
          onChange={e => handleChangeCurrentItem(e.target.value)}
          displayEmpty
          label={label}
          sx={{ backgroundColor: Colors.primary }}
          MenuProps={{
            PaperProps: {
              style: {
                boxSizing: 'content-box',
                transform: 'translate(0, -10px)',
                backgroundColor: PrimaryColors.white,
              },
            },
          }}
        >
          {items.map(item => (
            <MenuItem
              key={item.id}
              value={item.value}
              sx={{
                color: PrimaryColors.primary,
                padding: '10px 16px',
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
