import { Box, Typography } from '@mui/material';
import style from './MortgageColumnBox.module.scss';

function MortgageColumnBox({ title, value }) {
  return (
    <Box sx={{ width: '100%' }} className={style.estimationItem}>
      <Typography component='div' variant='h6' className={style.estimaionItemHeading}>
        {title}
      </Typography>
      <Typography className={style.estimaionItemSum}>
        {new Intl.NumberFormat('ru-RU').format(Number.parseInt(value))} ₽
      </Typography>
    </Box>
  );
}
export default MortgageColumnBox;
