import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

export const ModalFilterSlice = createSlice({
  name: 'modalFilter',
  initialState,
  reducers: {
    //открытие модального окна
    openModal: state => {
      state.open = true;
    },
    // закрытие модального окна
    closeModal: state => {
      state.open = false;
    },
  },
});

export const { openModal, closeModal } = ModalFilterSlice.actions;

export default ModalFilterSlice.reducer;
