import { createSlice } from '@reduxjs/toolkit';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const initialState = {
  source: [],
  source_comment: '',
  purchase_purpose: [],
  purchase_purpose_comment: '',
  type_of_property: [],
  price: [20, 5000],
  area: [30, 63000],
  acquisition_period: [],
  form_of_payment: [],
  competitors: [],
  extra_competitors: [],
  values: [],

  attractiveness: [],
  importance: [],
  employees: [20, 210],
  additional_characteristics: [],
  species_characteristics: [],
  lighting_characteristics: [],
  finishing: [],
};

export const formSliceStep = createSlice({
  name: 'formSliceStep',
  initialState,
  reducers: {
    saveFormDataStep: (state, action) => {
      const formDataStep = { ...state, ...action.payload };
      localStorage.setItem('formDataStep', JSON.stringify(formDataStep));
      return { ...state, ...formDataStep };
    },
  },
});

export const formCommercialManagerApi = createApi({
  reducerPath: 'formCommercialManagerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getFormData: builder.query({
      query: meetingId => `${DOMAIN_RELATIVE}/api/meetings/anketa/${meetingId}`,
    }),
    postFormData: builder.mutation({
      query: ({ meetingId, data }) => ({
        url: `${DOMAIN_RELATIVE}/api/meetings/anketa/${meetingId}`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetFormDataQuery, usePostFormDataMutation } = formCommercialManagerApi;
export const { saveFormDataStep } = formSliceStep.actions;
export default formSliceStep.reducer;
