import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';

const initialState = {};

export const partnerProgramsSlice = createSlice({
  name: 'partnerProgramsSlice',
  initialState,
  reducers: {},
});

export const partnerProgramsApi = createApi({
  reducerPath: 'partnerProgramsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    //Получение всех партнерских программ
    getPartnerProgramsData: builder.query({
      query: ({ rowsPerPage, page, order, orderBy }) =>
        `${DOMAIN_RELATIVE}/api/partner-admin?pagination={"page":${page},"perPage":${rowsPerPage}}&sort={"field":"${orderBy}","order":"${order}"}&filter={}`,
    }),
    //Удаление программ
    deletePartnerPrograms: builder.mutation({
      query: ({ programsId }) => ({
        url: `${DOMAIN_RELATIVE}/api/partner-admin${programsId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'PartnerPrograms', id: 'PartnerPrograms' }],
    }),
    //Получение одной программы по id
    getPartnerProgramsOne: builder.query({
      query: ({ id }) => `${DOMAIN_RELATIVE}/api/partner-admin/${id}`,
    }),
    //Создание новой программы
    addPartnerProgram: builder.mutation({
      query: ({ data }) => ({
        url: `/api/partner-admin`,
        method: 'POST',
        credentials: 'include',
        body: { data },
      }),

      invalidatesTags: [{ type: 'PartnerPrograms', id: 'PartnerPrograms' }],
    }),
    updatePartnerProgram: builder.mutation({
      query: ({ data, id }) => ({
        url: `/api/partner-admin/${id}`,
        method: 'PATCH',
        credentials: 'include',
        body: { data },
      }),

      invalidatesTags: [{ type: 'PartnerPrograms', id: 'PartnerPrograms' }],
    }),
  }),
});

export const {
  useGetPartnerProgramsDataQuery,
  useDeletePartnerProgramsMutation,
  useGetPartnerProgramsOneQuery,
  useAddPartnerProgramMutation,
  useUpdatePartnerProgramMutation,
} = partnerProgramsApi;

export default partnerProgramsSlice.reducer;
