import { useEffect, useState } from 'react';

export const useSetMinFirstPayment = (minFirstPayment, firstPayment, handleChangeFirstPayment) => {
  const [isSetMinFirstPaymentChecked, setIsSetMinFirstPaymentChecked] = useState(false);

  const handleSetMinFirstPayment = () => {
    if (!isSetMinFirstPaymentChecked) {
      handleChangeFirstPayment(minFirstPayment);
    }
  };

  useEffect(() => {
    setIsSetMinFirstPaymentChecked(firstPayment === minFirstPayment);
  }, [firstPayment, minFirstPayment]);

  return { isSetMinFirstPaymentChecked, handleSetMinFirstPayment };
};
