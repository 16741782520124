import { useEffect, useMemo, useState } from 'react';
import { fetchMortgagesPrice } from './calculator-logic/dataFetchers';
import { useGetPriceCalcData } from './calculator-logic/useGetPriceCalcData';
import { useSelectBenefit } from './calculator-logic/useSelectBenefit';
import { useSelectFirstPayment } from './calculator-logic/useSelectFirstPayment';
import { useSelectFlatPrice } from './calculator-logic/useSelectFlatPrice';
import { useSelectMortgageGroup } from './calculator-logic/useSelectMortgageGroup';
import { useSelectProject } from './calculator-logic/useSelectProject';
import { useSelectTerm } from './calculator-logic/useSelectTerm';
import { useSetMinFirstPayment } from './calculator-logic/useSetMinFirstPayment';
import { useSwitchPercentOrSum } from './calculator-logic/useSetPercentOrSum';

export const useCalculatorLogicForPrice = (uniqueId, statusSetters) => {
  const { handleSetErrorStatus, handleSetLoadedStatus, handleSetPendingStatus } = statusSetters;

  const [mortgagesList, setMortgagesList] = useState([]);
  const { activeProject, handleChangeActiveProjectId, activeProjectId } = useSelectProject(uniqueId);
  const { benefitGroup, groups, handleChangeBenefitGroup } = useSelectMortgageGroup(mortgagesList, uniqueId);

  const benefits = useMemo(() => {
    if (benefitGroup?.id) {
      return mortgagesList.filter(i => i.group === benefitGroup.id);
    }
    return mortgagesList;
  }, [benefitGroup?.id, mortgagesList]);

  const { benefit, handleChangeBenefit } = useSelectBenefit(activeProject, benefits, uniqueId);
  const { flatPrice, handleChangeFlatPrice } = useSelectFlatPrice(uniqueId);
  const {
    initialPayment,
    firstPayment,
    handleChangeFirstPayment,
    handleChangeFirstPaymentCommitted,
    firstPaymentCommitted,
  } = useSelectFirstPayment(benefit, flatPrice, uniqueId, statusSetters);

  const minFirstPayment = initialPayment?.initialPaymentData || 0;
  const maxFirstPayment = initialPayment?.maxInitialPayment || 1;
  const { term, handleChangeTerm, termCommitted, handleChangeTermCommitted } = useSelectTerm(
    uniqueId,
    benefit?.maxYears,
    activeProjectId,
  );
  const { handleSetMinFirstPayment, isSetMinFirstPaymentChecked } = useSetMinFirstPayment(
    initialPayment?.initialPaymentData || firstPayment,
    firstPayment,
    handleChangeFirstPaymentCommitted,
  );
  const { priceCalcData: calcData, handleGetPriceCalcData: handleGetCalcData } = useGetPriceCalcData(
    { minFirstPayment, maxFirstPayment },
    termCommitted,
    firstPaymentCommitted,
    flatPrice,
    benefit,
    statusSetters,
  );

  const priceWithMargin = calcData?.priceWithMargin ?? initialPayment?.priceWithMargin;

  const { handleSwitchValue, isPercent, percentFirstPayment, handleChangePercentValue } = useSwitchPercentOrSum(
    handleChangeFirstPaymentCommitted,
    firstPayment,
    priceWithMargin,
  );

  useEffect(() => {
    if (activeProject?.blockId) {
      handleSetPendingStatus();
      fetchMortgagesPrice(activeProject.blockId)
        .then(result => {
          handleSetLoadedStatus();
          setMortgagesList(result);
        })
        .catch(() => handleSetErrorStatus());
    }
  }, [activeProject, activeProject?.blockId, handleSetErrorStatus, handleSetLoadedStatus, handleSetPendingStatus]);

  useEffect(() => {
    handleGetCalcData();
  }, [handleGetCalcData]);

  const projectHasChanged = () => {
    handleChangeBenefitGroup('');
    handleChangeTerm();
    benefitGroupHasChanged();
  };

  const benefitGroupHasChanged = () => {
    handleChangeBenefit();
  };

  const getFormatGroups = () => {
    return groups.map(group => {
      return {
        title: group.group === '-' ? 'Без группировки' : group.group,
        id: group.group,
        value: group.group,
      };
    });
  };

  const minFlatPrice = initialPayment?.initialPaymentData || 0;
  const maxFlatPrice = initialPayment?.maxInitialPayment || 1;

  const currentBenefit = mortgagesList.find(item => Number(item.id) === Number(benefit?.id));

  const maxTerm = benefit?.maxYears || 1;
  const minTerm = benefit?.minYears || 1;

  return {
    activeProject,
    handleChangeActiveProjectId,
    activeProjectId,
    benefit,
    benefits: benefits.map(benefit => ({ title: benefit.name, id: String(benefit.id), value: String(benefit.id) })),
    handleChangeBenefit,
    benefitId: benefit?.id,
    initialPayment,
    firstPayment,
    handleChangeFirstPayment,
    handleChangeFirstPaymentCommitted,
    term,
    handleChangeTerm,
    handleSetMinFirstPayment,
    isSetMinFirstPaymentChecked,
    handleSwitchValue,
    isPercent,
    percentFirstPayment,
    minFirstPayment,
    maxFirstPayment,
    flatPrice,
    handleChangePercentValue,
    priceWithMargin,
    calcData,
    handleChangeFlatPrice,
    groups: getFormatGroups(),
    benefitGroup,
    handleChangeBenefitGroup,
    projectHasChanged,
    benefitGroupHasChanged,
    mortgagesList,
    minFlatPrice,
    maxFlatPrice,
    firstPaymentCommitted,
    currentBenefit,
    maxTerm,
    minTerm,
    termCommitted,
    handleChangeTermCommitted,
  };
};
