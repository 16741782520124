import axios from 'axios';
import { DOMAIN_RELATIVE } from './constants.js';
import { clearUserData } from './utils.js';
import { partnerRedirectHandler } from './partnerRedirectHandler.js';

export const checkAuth = async () => {
  const user = JSON.parse(localStorage.getItem('user'));

  try {
    const response = await axios.get(`${DOMAIN_RELATIVE}/api/auth/checkRole`, {
      withCredentials: true,
    });
    if (!response.data) {
      partnerRedirectHandler(); // проверяем нет ли редиректа в partner; редиректим если есть
    }
    return true;
  } catch (error) {
    if (user && error?.response?.data?.statusCode === 401) {
      clearUserData(false);
    }
    return false;
  }
};
