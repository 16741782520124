const sortDate = (array, prop, direction) => {
  const propPath = prop.split('.');

  let sortOrder;
  if (direction === 'asc_') {
    sortOrder = 1;
  } else {
    sortOrder = -1;
  }

  return array.sort((a, b) => {
    let x = a;
    let y = b;
    for (const element of propPath) {
      x = x[element];
      y = y[element];
    }

    // Convert x and y to Date objects
    x = new Date(x);
    y = new Date(y);

    return sortOrder * (x < y ? -1 : x > y ? 1 : 0);
  });
};

export default sortDate;
