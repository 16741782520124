import { Box, Card, Stack, ThemeProvider, Typography } from '@mui/material';
import { Colors } from '../../../assets/mui-styles/Colors';
import { equalizerTheme } from '../../../assets/mui-styles/GlobalStyles';
import { EqualizerBookButton } from '../../equalizer-page-new/equalizer-book-button/EqualizerBookButton';
import { EqualizerCompareButton } from '../../equalizer-page-new/equalizer-compare-button/EqualizerCompareButton';
import style from './EstimationBlock.module.scss';
import DOMPurify from 'dompurify';
import MortgageColumnBox from '../../../widgets/mortgage-column-box/MortgageColumnBox.jsx';

export const EstimationBlock = ({
  flatPrice,
  benefitId,
  currentBenefit,
  calcData,
  initialPayment,
  fetchStatus,
  flatsData,
  meetingId,
  termCommitted,
  term,
  flatData,
  firstPayment,
  isLotTab,
  isEqualizer,
  activeProject,
  firstPaymentCommitted,
  benefits,
  priceWithMargin,
}) => {
  const monthlyPaymentSubsidy = calcData?.monthlyPaymentSubsidy;
  const monthlySubsidyExists = Boolean(monthlyPaymentSubsidy);
  const amountOfCredit =
    isLotTab && isEqualizer
      ? currentBenefit?.benefitPrice - currentBenefit?.mortgage?.firstPayment
      : calcData?.amountOfCredit || initialPayment?.amountOfCredit;
  const normalMonthlyPayment =
    isLotTab && isEqualizer ? currentBenefit?.mortgage?.minMonthFee : calcData?.monthlyPayment;
  const subsidyTerm = calcData?.subsidyTerm;
  return (
    <Card className={style.estimationBlockContainer} sx={{ background: Colors.additional }}>
      {fetchStatus === 'pending' && <div className={style.whiteOverlay} />}

      <Stack
        direction='column'
        alignItems='start'
        justifyContent='start'
        spacing='24px'
        sx={{ mb: '5%' }}
        className={style.estimationBlockStack}
      >
        {currentBenefit && (
          <>
            <Stack
              direction='column'
              alignItems='start'
              justifyContent='start'
              spacing='24px'
              sx={{
                padding: '0 16px',
                width: '100%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography
                  component='div'
                  variant='h2'
                  sx={{
                    fontSize: 24,
                  }}
                >
                  {currentBenefit?.name}
                </Typography>
              </Box>

              <Stack
                display='grid'
                gridTemplateColumns='repeat(auto-fit, minmax(100px, 1fr))'
                gap='24px'
                sx={{
                  width: '100%',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  {currentBenefit.description && (
                    <Typography
                      component='div'
                      variant='div'
                      sx={{
                        fontSize: 12,
                        lineHeight: '150%',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(currentBenefit?.description),
                      }}
                    />
                  )}

                  {currentBenefit.minFirstPaymen && (
                    <p className={style.descParagraph}>ПВ от {currentBenefit.minFirstPaymen}%</p>
                  )}

                  {Boolean(currentBenefit.rate) && <p className={style.descParagraph}>Ставка {currentBenefit.rate}%</p>}

                  {currentBenefit.maxYears && (
                    <p className={style.descParagraph}>Срок до {currentBenefit.maxYears} лет</p>
                  )}

                  {currentBenefit.maxSumCredit && (
                    <p className={style.descParagraph}>
                      Сумма кредита до {currentBenefit.maxSumCredit / 1000000} млн.р
                    </p>
                  )}
                </Box>

                {flatPrice && (
                  <Box sx={{ width: '100%' }} className={style.realPrice}>
                    <Typography className={style.estimaionItemPrice}>
                      {new Intl.NumberFormat('ru-RU').format(Number.parseInt(flatPrice, 10))} ₽
                    </Typography>
                    <p>Прайсовая цена</p>
                  </Box>
                )}
              </Stack>
            </Stack>
          </>
        )}

        {(initialPayment?.priceWithMargin || priceWithMargin) && (
          <div className={style.estimationBlockInnerStack}>
            <Stack sx={{ gap: '20px' }}>
              <MortgageColumnBox
                title={'Стоимость квартиры по программе'}
                value={calcData?.priceWithMargin ?? initialPayment?.priceWithMargin ?? priceWithMargin}
              />
              {monthlySubsidyExists && (
                <MortgageColumnBox title={'Ежемесячный платеж на льготный период'} value={monthlyPaymentSubsidy} />
              )}
            </Stack>

            {(!(isLotTab && isEqualizer) || currentBenefit?.mortgage) && (
              <Stack sx={{ gap: '20px' }}>
                <MortgageColumnBox title='Сумма кредита' value={amountOfCredit} />
                <MortgageColumnBox
                  title={
                    monthlySubsidyExists
                      ? `Ежемесячный платеж ${subsidyTerm + 1 === 2 ? 'cо' : 'c'} ${subsidyTerm + 1} года`
                      : 'Ежемесячный платеж'
                  }
                  value={normalMonthlyPayment}
                />
              </Stack>
            )}
          </div>
        )}

        {isLotTab && isEqualizer && (
          <ThemeProvider theme={equalizerTheme}>
            <Stack className={style.buttonStack}>
              {currentBenefit.mortgage !== null && currentBenefit.group === 'mortgage' && (
                <EqualizerCompareButton
                  meetingId={meetingId}
                  benefitId={benefitId}
                  flats={flatsData}
                  flatData={flatData}
                  loanTerm={termCommitted}
                  firstPaymentCommitted={firstPaymentCommitted}
                  benefits={benefits}
                />
              )}
              <EqualizerBookButton
                firstPayment={firstPayment}
                flatData={flatData}
                benefit={currentBenefit}
                term={term}
                activeProject={activeProject}
              />
            </Stack>
          </ThemeProvider>
        )}
      </Stack>
    </Card>
  );
};
