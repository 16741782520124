import { useEffect, useMemo, useState } from 'react';
import { initialProjects } from './initialProjects';

export const useSelectProject = uniqueId => {
  const [activeProjectId, setActiveProjectId] = useState();

  const currentProject = useMemo(() => {
    return initialProjects.find(project => project.value === activeProjectId);
  }, [activeProjectId]);

  const handleChangeActiveProjectId = newId => {
    setActiveProjectId(newId);
    sessionStorage.setItem(`activeProjectNew${uniqueId}`, newId);
  };

  useEffect(() => {
    // в storage могут лежать:
    // id - если меняем проект в калькуляторе
    // title - если начали встречу (нет возможности согласовать интерфейсы; id проекта при создании встречи и тут - разные)
    const storageItem = sessionStorage.getItem(`activeProjectNew${uniqueId}`);

    if (!storageItem) {
      const project = initialProjects?.find(
        project => project.title.toUpperCase() === 'FØRST' || project.title.toUpperCase() === 'FORST',
      );
      setActiveProjectId(project.value);
      return;
    }

    // если в storage id - то возвращаем id
    const parsedNumber = Number(storageItem);
    if (!isNaN(parsedNumber)) {
      setActiveProjectId(storageItem);
      return;
    }
    // если в storage title - ищем проект в initialProjects с таким же title и возвращаем его id
    const project = initialProjects.find(
      project => project.title.toString().toLowerCase() === storageItem.toString().toLowerCase(),
    );
    if (project) {
      setActiveProjectId(project.value);
      return;
    }
  }, []);

  return { activeProject: currentProject, handleChangeActiveProjectId, activeProjectId };
};
