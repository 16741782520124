import { createSlice } from '@reduxjs/toolkit';

const initialState = { isInternetConnected: true };

export const connection = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setInternetConnection(state, action) {
      state.isInternetConnected = action.payload;
    },
  },
});

export const { setInternetConnection } = connection.actions;

export default connection.reducer;
