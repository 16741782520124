import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Card, Typography } from '@mui/material';
import ButtonFull from '../../features/button-full/ButtonFull';
import { clearUserData, handleBodyLock, handleReload } from '../../utils/utils';
import style from './ModalError.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { handleIsError } from '../../entities/ComponentsSlice.js';
import CloseIcon from '@mui/icons-material/Close';

const ModalError = () => {
  handleBodyLock();

  const dispatch = useDispatch();
  const errorCode = useSelector(state => state.componentsState.error.code);
  const errorMessage = useSelector(state => state.componentsState.error.message);

  return (
    <Box className={style.modalErrorContainer}>
      <Card className={style.modalErrorCard}>
        <ErrorOutlineIcon color='error' sx={{ fontSize: '80px', mb: 2 }} />
        <Typography variant='h4' sx={{ fontWeight: 600 }} color='error'>
          ОШИБКА
        </Typography>
        <Typography variant='h2' sx={{ fontWeight: 600, mt: '20px' }} color='secondary'>
          {errorCode}
        </Typography>
        <Typography variant='h6' sx={{ mt: 3, mb: 8, width: '70%', textAlign: 'center' }}>
          {errorMessage}
        </Typography>
        <ButtonFull
          handleFunction={() => {
            dispatch(handleIsError({ isError: false, message: '', code: '' }));
            handleReload();
          }}
          text='Перезагрузить страницу'
          color='error'
        />
        <ButtonFull
          handleFunction={() => {
            dispatch(handleIsError({ isError: false, message: '', code: '' }));
            clearUserData();
          }}
          text='Покинуть аккаунт'
          color='secondary'
        />

        <CloseIcon
          onClick={() => dispatch(handleIsError({ isError: false, message: '', code: '' }))}
          className={style.closeIcon}
        />
      </Card>
    </Box>
  );
};

export default ModalError;
