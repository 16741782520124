import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import style from './InputLogin.module.scss';

const InputLogin = ({ value, setValue }) => {
  const [validate, setValidate] = useState(true);
  const [helperText, setHelperText] = useState('');

  const validateEmail = email => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email.toString().toLowerCase(),
    );
  };

  const handleChangeValue = event => {
    setValue(event.target.value);
    setValidate(validateEmail(event.target.value));
  };

  useEffect(() => {
    if (!validate) {
      setHelperText('Введите корректный email');
    } else {
      setHelperText('');
    }
  }, [validate]);

  return (
    <TextField
      error={!validate}
      type='email'
      color='secondary'
      value={value}
      autoComplete='on'
      helpertext={helperText}
      onChange={handleChangeValue}
      variant='outlined'
      className={style.inputLoginInput}
      label='Логин'
    />
  );
};

export default InputLogin;
