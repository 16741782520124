import { createTheme } from '@mui/material/styles';
import { Colors, Mixins, PrimaryColors } from './Colors';
import { typographySettings } from './Typography';

export const breakpointsValues = {
  md: 1600,
  bg: 1920,
  lg: 2560,
};

export const basicLayout = {
  headerHeight: '104px',
};

export const primaryTheme = createTheme({
  palette: {
    primary: {
      main: PrimaryColors.primary,
    },
    secondary: {
      main: PrimaryColors.secondary,
    },
    success: {
      main: PrimaryColors.success,
    },
    text: {
      primary: PrimaryColors.white,
      disabled: PrimaryColors.disabled,
      secondary: PrimaryColors.textSecondary,
    },
    grey: {
      main: PrimaryColors.greyText,
    },
  },
  breakpoints: {
    values: breakpointsValues,
  },
  components: {
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          color: PrimaryColors.primary,
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: '9999',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: PrimaryColors.primary,
        },
      },
    },
    MuiPickersFadeTransitionGroup: {
      styleOverrides: {
        root: {
          color: PrimaryColors.primary,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: PrimaryColors.secondary,
          whiteSpace: 'pre',
          textAlign: 'center',
          ...typographySettings.body1,
        },
        iconContainer: {
          padding: 0,
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        horizontal: {
          padding: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
          border: `1px solid ${PrimaryColors.borderLessOpacity}`,
          color: PrimaryColors.primary,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          width: '100%',
          border: Mixins.primaryColorBorder,
          background: PrimaryColors.white,
          color: PrimaryColors.primary,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 0,
          color: PrimaryColors.primary,
          ...typographySettings.inputText,

          '& > fieldset': {
            border: 'none',
            borderRadius: 0,
          },
          '& .MuiInputBase-input': {
            '::placeholder': {
              opacity: 1,
            },
          },
          '& .MuiAutocomplete-input': {
            padding: '0px !important',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: PrimaryColors.primary,
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            height: '42px',
            borderRadius: 0,
            border: Mixins.primaryColorBorder,
            color: PrimaryColors.primary,
            background: 'transparent',
            ...typographySettings.buttonLarge,

            '& > div': {
              padding: '8px 48px 8px 22px !important',
            },

            '&:hover': {
              color: PrimaryColors.primary,
              background: PrimaryColors.hover,
            },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            '&::before, &::after': {
              display: 'none',
            },

            '& > div': {
              boxSizing: 'border-box',
              maxHeight: 'inherit',
              minHeight: 'inherit',
              height: 'inherit',
              padding: '9px 12px',
              borderRadius: 0,
              border: Mixins.defaultBorder,
              color: PrimaryColors.primary,
              background: PrimaryColors.white,
              ...typographySettings.inputText,

              '&:hover': {
                color: PrimaryColors.primary,
                background: PrimaryColors.hover,
              },
            },
          },
        },
      ],
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'fullWidth' },
          style: {
            width: '100%',
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
          textDecoration: 'none',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: PrimaryColors.primary,

          '&:hover': {
            background: 'transparent',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: PrimaryColors.primary,
        },
        white: {
          background: PrimaryColors.white,
          border: Mixins.defaultBorder,
          color: PrimaryColors.primary,
        },
      },
      variants: [
        {
          props: { variant: 'white' },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          boxShadow: 'none',
          borderRadius: 0,
          height: 'fit-content',
          ...typographySettings.buttonMedium,

          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            padding: '6px 16px',

            '@media (min-width: 2560px)': {
              padding: '10px 30px',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: PrimaryColors.primary,
            background: PrimaryColors.secondaryBG,

            '&:hover': {
              color: PrimaryColors.primary,
              background: PrimaryColors.hover,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            height: '42px',
            borderRadius: 0,
            border: Mixins.defaultBorder,
            color: PrimaryColors.primary,
            background: 'transparent',
            ...typographySettings.buttonLarge,

            '&:hover': {
              border: Mixins.defaultBorder,
              color: PrimaryColors.primary,
              background: PrimaryColors.hover,
            },

            '&:focus': {
              border: Mixins.primaryColorBorder,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            color: PrimaryColors.greyBG,
            background: PrimaryColors.primary,

            '&:hover': {
              color: PrimaryColors.primary,
              background: PrimaryColors.hover,
            },
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
          background: 'inherit',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          zIndex: 10,
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'square' },
          style: {
            borderRadius: 0,
          },
        },
        {
          props: { size: 'small' },
          style: {
            width: '32px',
            height: '32px',

            svg: {
              width: '24px',
              height: '24px',
            },

            '@media (min-width: 2560px)': {
              width: '59px',
              height: '59px',

              svg: {
                width: '35px',
                height: '35px',
              },
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            width: '291px',
            height: '36px',

            '@media (min-width: 2560px)': {
              height: '42px',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            borderRadius: 0,
            color: PrimaryColors.primary,
            background: PrimaryColors.secondaryBG,
            ...typographySettings.buttonMedium,

            '&:hover': {
              color: PrimaryColors.primary,
              background: PrimaryColors.hover,
            },
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            background: PrimaryColors.white,
            border: Mixins.defaultBorder,
            ...typographySettings.inputText,
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          '& .MuiTooltip-arrow': {
            color: PrimaryColors.tooltip,
          },

          width: '100%',
          textAlign: 'center',
          minWidth: '118px',
          backgroundColor: PrimaryColors.tooltip,
          padding: '4px 8px',
          borderRadius: '0',
          ...typographySettings.tooltip,

          '@media (min-width: 2560px)': {
            padding: '10px 15px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: PrimaryColors.secondary,

          '&.Mui-checked': {
            color: PrimaryColors.primary,
          },
        },
      },
    },
  },
  typography: {
    ...typographySettings,
  },
});

export const secondaryTheme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.grey,
    },
    additional: {
      main: Colors.additional,
      contrastText: Colors.primary,
    },
    neutral: {
      main: Colors.grey,
      contrastText: Colors.primary,
    },
    disabled: {
      main: Colors.lightGrey,
      contrastText: Colors.darkGrey,
    },
    anotherneutral: {
      main: Colors.superLightGrey,
      contrastText: Colors.lightGrey,
    },
    secondary: {
      main: Colors.secondary,
      contrastText: Colors.primary,
    },
    secondaryDark: {
      main: Colors.black,
      contrastText: Colors.secondary,
    },
    success: {
      main: Colors.success,
    },
    error: {
      main: Colors.error,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            color: Colors.primary,
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, 0.87)',
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: Colors.secondary,
          },
        },
      },
    },
  },
  typography: {
    ...typographySettings,
  },
});

export const equalizerTheme = createTheme({
  palette: {
    primary: {
      main: PrimaryColors.primary,
    },
  },
  breakpoints: {
    values: breakpointsValues,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
          border: `1px solid ${PrimaryColors.borderLessOpacity}`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 0,
          color: PrimaryColors.primary,
          fontFamily: 'Unica77LLCyr-Regular',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '.15px',
          '& > fieldset': {
            border: 'none',
            borderRadius: 0,
          },
          '& .MuiInputBase-input': {
            '::placeholder': {
              opacity: 1,
            },
          },
          '& .MuiAutocomplete-input': {
            padding: '0px !important',
          },

          '@media (min-width: 2560px)': {
            fontSize: 20,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: PrimaryColors.primary,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& > div': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            paddingLeft: '12px',
          },
          '&:hover': {
            background: PrimaryColors.greyBG,
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            color: PrimaryColors.primary,
            borderRadius: 0,
            border: Mixins.defaultBorder,
            background: PrimaryColors.white,
            height: '40px',

            '@media (min-width: 2560px)': {
              height: '44px',
            },
          },
        },
      ],
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'fullWidth' },
          style: {
            width: '100%',
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: PrimaryColors.primary,
        },
        white: {
          background: PrimaryColors.white,
          border: Mixins.defaultBorder,
          color: PrimaryColors.primary,
        },
      },
      variants: [
        {
          props: { variant: 'white' },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          boxShadow: 'none',
          borderRadius: 0,
          fontFamily: 'Unica77LLCyr-Medium',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '24px',
          letterSpacing: '-0.005em',
          height: 'fit-content',

          '&:hover': {
            boxShadow: 'none',
          },

          '@media (min-width: 2560px)': {
            fontSize: 18,
            lineHeight: '32px',
          },
        },
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            color: PrimaryColors.primary,
            background: PrimaryColors.secondaryBG,

            '&:hover': {
              color: PrimaryColors.primary,
              background: PrimaryColors.hover,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            color: PrimaryColors.greyBG,
            background: PrimaryColors.primary,

            '&:hover': {
              color: PrimaryColors.primary,
              background: PrimaryColors.hover,
            },
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
          background: 'inherit',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,

          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: '9999',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: PrimaryColors.primary,
        },
      },
    },
    MuiPickersFadeTransitionGroup: {
      styleOverrides: {
        root: {
          color: PrimaryColors.primary,
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            background: PrimaryColors.white,
            height: '40px',
            borderRadius: 0,
            border: Mixins.defaultBorder,
            fontFamily: 'Unica77LLCyr-Regular',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: PrimaryColors.primary,
            padding: 0,

            '.MuiInputBase-root': {
              height: '40px',
            },

            '&:hover': {
              background: PrimaryColors.hover,
            },

            '@media (min-width: 2560px)': {
              fontSize: 20,
              height: '44px',

              '.MuiInputBase-root': {
                height: '44px',
              },
            },
          },
        },
      ],
    },
  },
  typography: {
    ...typographySettings,
  },
});
