import { createSlice } from '@reduxjs/toolkit';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const initialState = {
  allContacts: [],
  allDeals: [],
  contact: {},
  deal: null,
  countPagination: 0,
  error: '',
  errorMessage: '',
  filteredContacts: [],
  filteredDeals: [],
  messageSent: false,
  pagePagination: 1,
  templates: [],
};

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    //***CONTACTS***
    // запрос для получения информации по одному лиду
    fetchContact: builder.query({
      query: id => `api/leads/${id}`,
    }),
    // запрос для получения всех лидов
    fetchAllContacts: builder.query({
      query: count => `api/leads?page=${count}`,
    }),
    // запрос для получения отфильтрованных лидов
    fetchFilteredContacts: builder.query({
      query: text => `api/leads?search=${text}`,
    }),
    // запрос для получения шаблонов контактов
    fetchTemplates: builder.query({
      query: id => `api/v2/waba/templates/${id}`,
    }),
    // запрос на отправку шаблона в wa
    sendWabaTemplate: builder.mutation({
      query: data => ({
        url: 'api/waba/message/send',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),

    //***DEALS***
    // запрос для получения информации по одной встречи
    fetchDeal: builder.query({
      query: id => `api/wa-apointments/apointment/${id}`,
    }),
    // запрос для получения всех встреч
    fetchAllDeals: builder.query({
      query: page => `api/wa-apointments/apointments?pagination={"page":${page},"perPage":50}`,
    }),
    // запрос для получения отфильтрованных встреч
    fetchFilteredDeals: builder.query({
      query: text => `api/wa-apointments/apointments?filter={"search":"${text}"}&pagination={"page":1,"perPage":1000}`,
    }),
    // запрос для получения шаблонов встреч
    fetchDealsTemplates: builder.query({
      query: id => `api/waba/templates/apointments/${id}`,
    }),
    // запрос на отправку шаблона встречи в wa
    sendDealsTemplate: builder.mutation({
      query: data => ({
        url: 'api/waba/message/apointments/send',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useFetchContactQuery,
  useFetchAllContactsQuery,
  useFetchFilteredContactsQuery,
  useFetchTemplatesQuery,
  useSendWabaTemplateMutation,
  useFetchDealQuery,
  useFetchAllDealsQuery,
  useFetchFilteredDealsQuery,
  useFetchDealsTemplatesQuery,
  useSendDealsTemplateMutation,
} = contactsApi;

export const WabaSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    clearFilterContactsAndDeals: state => {
      state.filteredContacts = [];
      state.filteredDeals = [];
    },
    changePagePagination: (state, action) => {
      state.pagePagination = action.payload;
    },
    deleteErrorMessage: state => {
      state.errorMessage = '';
    },
    cleanDealInfo: state => {
      state.deal = null;
    },
  },
  extraReducers: builder => {
    // extraReducers по контактам
    builder.addMatcher(contactsApi.endpoints.fetchContact.matchPending, state => {
      state.error = '';
      state.messageSent = false;
    });
    builder.addMatcher(contactsApi.endpoints.fetchContact.matchFulfilled, (state, action) => {
      state.contact = action.payload;
    });
    builder.addMatcher(contactsApi.endpoints.fetchContact.matchRejected, (state, action) => {
      state.contact = {};
      state.error = action.payload;
    });

    builder.addMatcher(contactsApi.endpoints.fetchAllContacts.matchPending, state => {
      state.error = '';
      state.messageSent = false;
    });
    builder.addMatcher(contactsApi.endpoints.fetchAllContacts.matchFulfilled, (state, action) => {
      const { result, total } = action.payload;
      const count = total > result.length ? Math.ceil(total / 50) : 0;

      state.allContacts = result;
      state.countPagination = count;
    });
    builder.addMatcher(contactsApi.endpoints.fetchAllContacts.matchRejected, (state, action) => {
      state.allContacts = [];
      state.error = action.payload;
    });

    builder.addMatcher(contactsApi.endpoints.fetchFilteredContacts.matchPending, state => {
      state.error = '';
      state.messageSent = false;
    });
    builder.addMatcher(contactsApi.endpoints.fetchFilteredContacts.matchFulfilled, (state, action) => {
      state.filteredContacts = action.payload.result;
    });
    builder.addMatcher(contactsApi.endpoints.fetchFilteredContacts.matchRejected, (state, action) => {
      state.filteredContacts = [];
      state.error = action.payload;
    });

    builder.addMatcher(contactsApi.endpoints.fetchTemplates.matchPending, state => {
      state.error = '';
      state.messageSent = false;
    });
    builder.addMatcher(contactsApi.endpoints.fetchTemplates.matchFulfilled, (state, action) => {
      state.templates = action.payload;
    });
    builder.addMatcher(contactsApi.endpoints.fetchTemplates.matchRejected, (state, action) => {
      state.templates = [];
      state.error = action.payload;
    });

    builder.addMatcher(contactsApi.endpoints.sendWabaTemplate.matchPending, state => {
      state.error = '';
    });
    builder.addMatcher(contactsApi.endpoints.sendWabaTemplate.matchFulfilled, state => {
      state.messageSent = true;
    });
    builder.addMatcher(contactsApi.endpoints.sendWabaTemplate.matchRejected, (state, action) => {
      state.messageSent = true;
      state.errorMessage = action.payload.data.errors[0].error;
    });

    // extraReducers по встречам
    builder.addMatcher(contactsApi.endpoints.fetchDeal.matchPending, state => {
      state.error = '';
      state.messageSent = false;
    });
    builder.addMatcher(contactsApi.endpoints.fetchDeal.matchFulfilled, (state, action) => {
      state.deal = action.payload;
    });
    builder.addMatcher(contactsApi.endpoints.fetchDeal.matchRejected, (state, action) => {
      state.deal = {};
      state.error = action.payload;
    });

    builder.addMatcher(contactsApi.endpoints.fetchAllDeals.matchPending, state => {
      state.allDeals = [];
      state.error = '';
      state.messageSent = false;
    });
    builder.addMatcher(contactsApi.endpoints.fetchAllDeals.matchFulfilled, (state, action) => {
      const { data, total } = action.payload;
      const count = total > data.length ? Math.ceil(total / 50) : 0;

      state.allDeals = data;
      state.countPagination = count;
    });
    builder.addMatcher(contactsApi.endpoints.fetchAllDeals.matchRejected, (state, action) => {
      state.allDeals = [];
      state.error = action.payload;
    });

    builder.addMatcher(contactsApi.endpoints.fetchFilteredDeals.matchPending, state => {
      state.error = '';
      state.messageSent = false;
    });
    builder.addMatcher(contactsApi.endpoints.fetchFilteredDeals.matchFulfilled, (state, action) => {
      state.filteredDeals = action.payload.data;
    });
    builder.addMatcher(contactsApi.endpoints.fetchFilteredDeals.matchRejected, (state, action) => {
      state.filteredDeals = [];
      state.error = action.payload;
    });

    builder.addMatcher(contactsApi.endpoints.fetchDealsTemplates.matchPending, state => {
      state.error = '';
      state.messageSent = false;
    });
    builder.addMatcher(contactsApi.endpoints.fetchDealsTemplates.matchFulfilled, (state, action) => {
      state.templates = action.payload;
    });
    builder.addMatcher(contactsApi.endpoints.fetchDealsTemplates.matchRejected, (state, action) => {
      state.templates = [];
      state.error = action.payload;
    });

    builder.addMatcher(contactsApi.endpoints.sendDealsTemplate.matchPending, state => {
      state.error = '';
    });
    builder.addMatcher(contactsApi.endpoints.sendDealsTemplate.matchFulfilled, state => {
      state.messageSent = true;
    });
    builder.addMatcher(contactsApi.endpoints.sendDealsTemplate.matchRejected, (state, action) => {
      state.messageSent = true;
      state.errorMessage = action.payload.data.errors[0].error;
    });
  },
});

export const { clearFilterContactsAndDeals, changePagePagination, deleteErrorMessage, cleanDealInfo } =
  WabaSlice.actions;

export default WabaSlice.reducer;
