import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Mixins, PrimaryColors } from '../../assets/mui-styles/Colors';
import { bookFlatSelector, changeBookFlat } from '../../entities/BookingSlice';
import { flatsSelector } from '../../entities/EqualizerSlice';

function BookFlatSelect({ cardFlat, isCommercialManager }) {
  const dispatch = useDispatch();

  const flats = useSelector(flatsSelector());
  const flat = useSelector(bookFlatSelector());

  const flatStorage = sessionStorage.getItem('currFlatEq');
  const flatFavouriteStorage = sessionStorage.getItem('flatFavouriteEq');

  const handleChangeFlat = event => {
    const { value } = event.target;
    dispatch(changeBookFlat(value));
  };

  useEffect(() => {
    if (flatStorage) {
      dispatch(changeBookFlat(flatStorage));
    } else if (flatFavouriteStorage) {
      dispatch(changeBookFlat(flatFavouriteStorage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack direction='column' spacing='5px' width='100%'>
        <Typography variant='h3' mb='20px'>
          Номер {isCommercialManager ? 'помещения' : 'квартиры'}
        </Typography>

        {cardFlat ? (
          <Box
            sx={{
              minHeight: '40px',
              background: PrimaryColors.white,
              padding: '0 12px',
              display: 'flex',
              alignItems: 'center',
              border: Mixins.defaultBorder,
            }}
          >
            {cardFlat.number}
          </Box>
        ) : (
          <Select variant='outlined' value={flat | ''} onChange={handleChangeFlat} displayEmpty>
            {flats?.map(item => (
              <MenuItem
                key={item.id}
                value={item.number}
                sx={{
                  color: PrimaryColors.primary,
                }}
              >
                {item.number}
              </MenuItem>
            ))}
          </Select>
        )}
      </Stack>
    </>
  );
}

export default BookFlatSelect;
