const sortNumber = (array, prop, direction) => {
  const propPath = prop.split('.');

  let sortOrder;
  if (direction === 'asc_') {
    sortOrder = 1;
  } else {
    sortOrder = -1;
  }

  return array.sort((a, b) => {
    let x = a;
    let y = b;
    for (const element of propPath) {
      x = x[element];
      y = y[element];
    }
    return sortOrder * (Number(x) < Number(y) ? -1 : Number(x) > Number(y) ? 1 : 0);
  });
};

export default sortNumber;
