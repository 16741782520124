import { useCallback, useEffect, useState } from 'react';
import { fetchInitialPayment } from './dataFetchers';

export const useSelectFirstPayment = (
  benefit,
  flatPrice,
  uniqueSuffix,
  { handleSetErrorStatus, handleSetPendingStatus, handleSetLoadedStatus },
  isEqualizer = false,
) => {
  const firstPaymentName = `currFirstPayment${uniqueSuffix}`;
  const [firstPayment, setFirstPayment] = useState(Number(sessionStorage.getItem(firstPaymentName)));
  const [initialPayment, setInitialPayment] = useState();
  const [firstPaymentCommitted, setFirstPaymentCommitted] = useState(Number(sessionStorage.getItem(firstPaymentName)));
  const handleChangeFirstPayment = useCallback(
    value => {
      setFirstPayment(value);
      sessionStorage.setItem(firstPaymentName, value);
    },
    [firstPaymentName],
  );

  const handleChangeFirstPaymentCommitted = useCallback(
    value => {
      handleChangeFirstPayment(value);
      setFirstPaymentCommitted(value);
    },
    [handleChangeFirstPayment],
  );

  useEffect(() => {
    if (benefit?.id && flatPrice && !isEqualizer) {
      handleSetPendingStatus();
      fetchInitialPayment({
        currBenefitLot: benefit.id,
        flatPrice,
      })
        .then(result => {
          handleSetLoadedStatus();
          setInitialPayment(result);
          if (firstPaymentCommitted > result.maxInitialPayment || firstPaymentCommitted < result.initialPaymentData) {
            handleChangeFirstPaymentCommitted(result.initialPaymentData);
          }
        })
        .catch(() => handleSetErrorStatus());
    }
  }, [
    benefit?.id,
    flatPrice,
    handleChangeFirstPaymentCommitted,
    handleSetErrorStatus,
    handleSetLoadedStatus,
    handleSetPendingStatus,
    isEqualizer,
  ]);

  useEffect(() => {
    const firstPaymentFromSS = sessionStorage.getItem(firstPaymentName);
    if (firstPaymentFromSS && firstPaymentFromSS !== 'undefined') {
      setFirstPayment(Number(firstPaymentFromSS));
      setFirstPaymentCommitted(Number(firstPaymentFromSS));
    }
  }, [firstPaymentName]);

  return {
    firstPayment,
    handleChangeFirstPayment,
    handleChangeFirstPaymentCommitted,
    initialPayment,
    flatPrice,
    firstPaymentCommitted,
  };
};
