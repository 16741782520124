import { Stack, Typography } from '@mui/material';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import { keyframes, styled } from '@mui/system';
import { Mixins, PrimaryColors } from '../../assets/mui-styles/Colors.js';

const slideUpAnimation = keyframes`
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
`;

const StyledStack = styled(Stack)(({ theme }) => ({
  border: Mixins.primaryColorBorder,
  background: PrimaryColors.white,
  position: 'fixed',
  top: 0,
  right: 0,
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  padding: '20px 0',
  minWidth: '20vw',
  alignItems: 'center',
  animation: `${slideUpAnimation} 1.5s ease-in`,
  zIndex: 1000,

  [theme.breakpoints.up('lg')]: {
    minWidth: '30vw',
  },
}));

const InternetConnectionFailModal = () => {
  return (
    <StyledStack>
      <Typography variant='body1' color='secondary'>
        Интернет соединение нарушено
      </Typography>
      <SignalWifiBadIcon />
    </StyledStack>
  );
};

export default InternetConnectionFailModal;
