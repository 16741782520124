import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CloseIcon } from '../../shared/icons/CloseIcon.jsx';
import { SearchIcon } from '../../shared/icons/SearchIcon.jsx';
import {
  clearFilterContactsAndDeals,
  useFetchFilteredContactsQuery,
  useFetchFilteredDealsQuery,
} from '../../entities/WabaSlice';
import ContactCard from '../contact-card/ContactCard';
import DealCard from '../deal-card/DealCard';
import styles from './SearchContacts.module.scss';

const disableBodyScroll = () => {
  document.body.style.overflow = 'hidden';
  document.body.style.touchAction = 'none';
};

const enableBodyScroll = () => {
  document.body.style.overflow = '';
  document.body.style.touchAction = '';
};

export const getPage = path => {
  if (!path) return '';
  const pathArr = path.split('/');
  return pathArr.find(el => el === 'deals' || el === 'leads');
};

const useDebouncedSearch = (searchTerm, delay = 500) => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, delay]);

  return encodeURIComponent(debouncedSearchTerm);
};

const SearchContacts = () => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebouncedSearch(searchTerm);

  const location = useLocation();
  const dispatch = useDispatch();
  const page = getPage(location.pathname);

  const { data: fetchedFilteredContacts } = useFetchFilteredContactsQuery(debouncedSearchTerm, {
    skip: (page !== 'leads') | (searchTerm?.length === 0),
  });

  const { data: filteredDeals } = useFetchFilteredDealsQuery(debouncedSearchTerm, {
    skip: (page !== 'deals') | (searchTerm?.length === 0),
  });

  const filteredContacts = fetchedFilteredContacts?.result;
  const lengthFilteredData = page === 'leads' ? filteredContacts?.length : filteredDeals?.data?.length;

  useEffect(() => {
    open ? disableBodyScroll() : enableBodyScroll();
  }, [open]);

  const onChangeSearchTerm = event => {
    setSearchTerm(event.target.value);
  };

  const onClickClose = () => {
    dispatch(clearFilterContactsAndDeals());
    setSearchTerm(null);
    setOpen(false);
  };

  return (
    <>
      <IconButton className={styles.icon} onClick={() => setOpen(true)}>
        <SearchIcon />
      </IconButton>
      {open && (
        <div className={styles.container}>
          <OutlinedInput
            className={styles.input}
            classes={{ notchedOutline: styles.inputHover }}
            type='text'
            value={searchTerm ?? ''}
            onChange={onChangeSearchTerm}
            placeholder={page === 'leads' ? 'Имя/название лида/телефон' : 'Имя/название встречи/телефон'}
            autoFocus={true}
            endAdornment={
              <InputAdornment position='end' sx={{ marginLeft: 0 }}>
                <IconButton edge='end' onClick={onClickClose} className={styles.btnClose}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          {filteredContacts?.length > 0 && (
            <div className={styles.list}>
              {filteredContacts?.map(lid => (
                <ContactCard key={lid.id} info={lid} func={onClickClose} />
              ))}
            </div>
          )}
          {filteredDeals?.data?.length > 0 && (
            <div className={styles.list}>
              {filteredDeals?.data?.map(deal => (
                <DealCard key={deal?.id} data={deal} searchTheme func={onClickClose} />
              ))}
            </div>
          )}
          {searchTerm && lengthFilteredData === 0 && (
            <div className={styles.emptyList}>
              <p className={styles.emptyList_text}>Ничего не найдено</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SearchContacts;
