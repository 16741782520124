import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { apiRoutes } from './api/api';
import { mockMeetingsSchedule, mockOperatingMode, mockVacationsManager } from '../mocks/meetingsSchedule';
// import meetingsScheduleAdapter from '../adapters/meetingsScheduleAdapter';

const initialState = {
  operatingModeModal: false,
  сreateEventDialog: false,
  startSelectedRanges: null,
  selectedBar: null,
};

export const meetingsScheduleSlice = createSlice({
  name: 'meetingsSchedule',
  initialState,
  reducers: {
    setOpenOpModeModal(state) {
      state.operatingModeModal = true;
    },
    setCloseOpModeModal(state) {
      state.operatingModeModal = false;
    },
    setOpenCreateEventDialog(state) {
      state.сreateEventDialog = true;
    },
    setCloseCreateEventDialog(state) {
      state.сreateEventDialog = false;
    },
    setStartSelectedRanges(state, action) {
      state.startSelectedRanges = action.payload;
    },
    setSelectedBar(state, action) {
      state.selectedBar = action.payload;
    },
  },
});

export const meetingsScheduleApi = createApi({
  reducerPath: 'meetingsScheduleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getProjectMeetings: builder.query({
      // query: projectId => `${apiRoutes.meetingsSchedule}?projectId=${projectId}`,
      queryFn: () => ({
        data: mockMeetingsSchedule,
      }),
    }),
    getOperatingMode: builder.query({
      // query: scheduleId => `${apiRoutes.operatingMode}/${scheduleId}`,
      queryFn: () => ({
        data: mockOperatingMode,
      }),
    }),
    createOperatingMode: builder.mutation({
      query: data => ({
        url: apiRoutes.operatingMode,
        method: 'POST',
        body: data,
      }),
    }),
    getVacationsManager: builder.query({
      queryFn: () => ({
        data: mockVacationsManager,
      }),
    }),
    createVacationManager: builder.mutation({
      query: data => ({
        url: apiRoutes.vacationsManager,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const selectOpModeModalStatus = state => state.meetingsScheduleSlice.operatingModeModal;
export const selectCreateEventDialogStatus = state => state.meetingsScheduleSlice.сreateEventDialog;
export const selectStartSelectedRanges = state => state.meetingsScheduleSlice.startSelectedRanges;
export const selectSelectedBar = state => state.meetingsScheduleSlice.selectedBar;

export const {
  setOpenOpModeModal,
  setCloseOpModeModal,
  setOpenCreateEventDialog,
  setCloseCreateEventDialog,
  setStartSelectedRanges,
  setSelectedBar,
} = meetingsScheduleSlice.actions;

export const {
  useGetProjectMeetingsQuery,
  useGetOperatingModeQuery,
  useCreateOperatingModeMutation,
  useGetVacationsManagerQuery,
  useCreateVacationManagerMutation,
} = meetingsScheduleApi;

export default meetingsScheduleSlice.reducer;
