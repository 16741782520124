import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../entities/api/routes';
import styles from './DealCard.module.scss';

export const getTime = time => {
  if (!time) return '';

  const timeArr = time?.split(':');
  return `${timeArr[0]}:${timeArr[1]}`;
};

const DealCard = ({ id, data, searchTheme = false, func }) => {
  const navigate = useNavigate();

  const onClickCard = id => {
    navigate(`${ROUTES.waba.deals}/${id}`);
    func?.();
  };

  return (
    <div id={id} className={styles.card} onClick={() => onClickCard(data?.id)}>
      <div className={styles.info}>
        <h6 className={searchTheme ? styles.text_bold : styles.title}>{data?.title || ''}</h6>
        {!searchTheme ? (
          <div className={styles.container}>
            <div className={styles.block}>
              <span className={styles.text_bold}>{data?.project || ''}</span>
              <span className={styles.text_bold}>Переговорная {data?.office || ''}</span>
            </div>
            {(data?.date || data?.time) && (
              <div className={styles.block}>
                {data?.date && <span className={styles.text_bold}>{dayjs(data?.date).format('DD.MM.YYYY') || ''}</span>}
                <span className={styles.text_bold}>{getTime(data?.time)}</span>
              </div>
            )}
            <div className={styles.block}>
              <span className={styles.text}>#{data?.bitrixId || ''}</span>
              <span className={styles.text}>{data?.name || ''}</span>
            </div>
            <div className={styles.block}>
              <span className={styles.text}>{data?.phone || ''}</span>
              <span className={`${styles.text} ${styles.text_align_end}`}>Статус: {data?.stage || ''}</span>
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.block}>
              <span className={styles.text_search_theme}>#{data?.bitrixId || ''}</span>
              <span className={styles.text_search_theme}>{data?.name || ''}</span>
            </div>
            <div className={styles.block}>
              <span className={styles.text}>{data?.phone || ''}</span>
              <span className={`${styles.text} ${styles.text_align_end}`}>Статус: {data?.stage || ''}</span>
            </div>
            <div className={styles.block}>
              <span className={styles.text}>{data?.project || ''}</span>
              {(data?.date || data?.time) && (
                <span className={styles.text}>
                  {data?.date ? dayjs(data?.date).format('DD.MM.YYYY') : ''}{' '}
                  {data?.time ? `в ${getTime(data?.time)}` : ''}
                </span>
              )}
            </div>
            <div className={styles.block}>
              <span className={styles.text}>Переговорная {data?.office || ''}</span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.icon}>
        <svg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M2.30062 0.75L0.949371 2.10125L5.33854 6.5L0.949371 10.8987L2.30062 12.25L8.05062 6.5L2.30062 0.75Z'
            fill='#333130'
          />
        </svg>
      </div>
    </div>
  );
};

export default DealCard;
