import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { calcDataPriceSelector, initialPaymentPriceSelector } from '../../entities/ProjectSlice';
import MortgageColumnBox from '../mortgage-column-box/MortgageColumnBox';
import style from './MortgageColumnPrice.module.scss';

function MortgageColumnPrice() {
  const initialPaymentPrice = useSelector(initialPaymentPriceSelector());
  const calcDataPrice = useSelector(calcDataPriceSelector());

  return (
    <Stack className={style.wrapper}>
      <MortgageColumnBox
        title='Сумма кредита'
        value={calcDataPrice.amountOfCredit ? calcDataPrice.amountOfCredit : initialPaymentPrice.amountOfCredit}
      />
      <MortgageColumnBox
        title='Ежемесячный платеж'
        value={calcDataPrice.monthlyPayment ? calcDataPrice.monthlyPayment : initialPaymentPrice.monthlyPayment}
      />
    </Stack>
  );
}
export default MortgageColumnPrice;
