//Утилита, рассчитывающая наименьшее значение в массиве объектов по ключу
const calcTheLeast = (array, key, max) => {
  const res = [...array];
  let min = max;

  for (const flat of res) {
    let parameter = key;
    if (key === 'price') {
      parameter = 'currentPrice';
    } else {
      parameter = key;
    }

    if (flat[parameter] < min) {
      min = flat[parameter];
    }
  }
  return min;
};

export default calcTheLeast;
